// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

export const DEF_LANG = {
  ADMIN: "Admin",
  WELCOME: "Welcome",
  START: "Start",
  INTRODUCTION: "introduction",
  LETS_BEGIN: "Let's begin...",
  YES: "Yes",
  NO: "No",
  OFF: "Off",
  ON: "On",
  CANCEL: "Cancel",
  CLOSE: "Close",
  RESET: "Reset",
  GO_BACK: "Go back",
  CONTINUE: "Continue",
  DONE: "Done",
  ADD: "Add",
  OR: "or",
  PROCEED: "Proceed",
  SELECT: "Select",
  OTHER: "Other",
  HERE: "Here",
  SCOPE: "Scope",
  DISTRIBUTION: "Distribution",
  PROGRAM: "Program",
  DOWNLOAD_AS_PDF: "Download as PDF",
  CATEGORY: "Category",
  CHOOSE_CATEGORY: "Choose category",
  TITLE: "Title",
  NAME: "Name",
  TYPE: "Type",
  DESCRIPTION: "Description",
  DEFAULT: "default",
  CHANGE: "Change",
  CHANGELOG: "Changelog",
  DOWNLOAD: "Download",
  ISSUES: "Issues",
  LANGUAGE: "Language",
  CHOOSE_LANGUAGE: "Choose language",
  WORLDWIDE: "Worldwide",
  REQUIRED: "required",
  APPROVE: "Approve",
  REJECT: "Reject",
  PASSED: "Passed",
  PENDING: "Pending",
  APPROVED: "Approved",
  READ_MORE: "Read more",
  REJECTED: "Rejected",
  TESTING: "Testing",
  FAILED: "Failed",
  SAVE: "Save",
  SUBMIT: "Submit",
  UPLOAD: "Upload",
  UPDATE: "Update",
  SUBMIT_CHANGES: "Submit changes",
  CONFIRM: "Confirm",
  SEND: "Send",
  DELETE: "Delete",
  SAVING: "Saving...",
  DISCARDING: "Discarding...",
  SUBMITTING: "Submitting...",
  VALIDATING: "Validating...",
  UPLOADING: "Uploading...",
  REMOVING: "Removing...",
  LOADING: "Loading",
  ALL: "all",
  UNDERSTAND: "I understand",
  EDIT: "Edit",
  DETAILS: "Details",
  CREATE: "Create",
  STATUS: "Status",
  STATUS_NEW: "New",
  STATUS_TEST: "Test",
  STATUS_DRAFT: "Draft",
  STATUS_SUBMITTED: "Submitted",
  PLEASE_SPECIFY: "Please specify",
  SHOW_PREVIEW: "Show preview",
  IMAGE_UPLOADED: "Image uploaded",
  CHOOSE_FILE: "Choose file",
  INVALID_IMAGE_FORMAT: "Unsupported image file, only #{formats} are allowed.",
  DISABLED: "Disabled",
  PREV: "Prev",
  NEXT: "Next",
  LAST: "Last",
  FIRST: "First",
  PREVIOUS: "Previous",
  APPS: "Apps",
  APPLICATIONS: "Applications",
  ORGANIZATION: "Organization",
  ORGANIZATION_NAME: "Organization Name",
  DISTRIBUTOR: "Distributor",
  CHOOSE_DISTRIBUTOR: "Choose distributor",
  WEBSITE: "Website",
  CHANGE_MAJOR: "Major",
  CHANGE_MINOR: "Minor",
  PREMIUM: "Premium",
  STANDARD: "Standard",
  EXCLUSIVE: "Exclusive",
  EXAMPLE: "Example",
  UPGRADE: "Upgrade",
  DOWNGRADE: "Downgrade",
  VEWD: "Vewd",
  MODERATION: "Moderation",
  VERSIONS: "Versions",
  VERSION: "version",
  USERS: "Users",
  ICON: "Icon",
  ROLES: "Roles",
  ROLE: "Role",
  NEW_ROLE: "New Role",
  EMAIL: "E-mail",
  FIRST_NAME: "First name",
  LAST_NAME: "Last name",
  VIEW_DETAILS: "View Details",
  EDIT_DETAILS: "Edit Details",
  BASIC_DETAILS: "Basic details",
  WARNING: "Warning",
  PAGINATION_ERROR_INVALID_PAGE: "Page does not exist",
  PAGINATION_INPUT_PLACEHOLDER: "Page",
  TABLE_EMPTY: "There are no items to show...",
  CHANGES_SAVE_SUCCESS: "Your changes have been saved successfully",
  TEST_DEVICE: "Test Device",
  DEVICE_NAME: "Device Name",
  DEVICE_FAMILY_NAME: "Device family name",
  PUBLISH: "Publish",
  COUNTRY: "country",
  COUNTRIES: "countries",
  SEARCH: "Search",
  SEARCH_BY_ID_OR_NAME: "Search by id or name",
  CHECK_UNCHECK_ALL: "Check / Uncheck All",
  ITEMS_SELECTED_ONE: "item selected",
  ITEMS_SELECTED_MANY: "items selected",
  CLICK_FOR_MORE_DETAILS: "Click for more details...",
  POSSIBLE_VALUES: "possible values",
  START_DATE: "Start date",
  END_DATE: "End date",
  SELECT_START_DATE_TOOLTIP: "Select chart start date",
  TODAY: "Today",
  WEEK: "week",
  MONTH: "month",
  YEAR: "year",
  YES_PROCEED: "Yes, proceed",
  DEFAULT_ERROR_MESSAGE: "An unspecified error has occurred",
  DEFAULT_REQUEST_ERROR_MESSAGE: "Something went wrong... Please, try again.",
  DEFAULT_FORM_SUBMIT_ERROR_MESSAGE:
    "Oops... It seems that you forgot about mandatory fields, or there are other errors in the form.",
  TIME_FIELD_FORMAT_ERROR: "Provided value does not match mm:ss format",
  ALL_DATA_LOADED: "All data loaded",
  SEARCH_AND_FILTER: "Search & Filter",
  FILTER: "Filter",
  DEFAULT_SECTION: "Suggested filters",
  COPYRIGHT: "Copyright © 2019-2022 Vewd. All Rights Reserved",
  INITIALIZING: "Initializing",
  REDIRECTING_TO_LOGIN_PAGE: "Redirecting to the login page",
  HELLO: "Hello",
  MORE: "more",
  MY_ACCOUNT: "My Account",
  LOGOUT: "Logout",
  SCHEDULED: "Scheduled",
  ONGOING: "Ongoing",
  FINISH: "Finish",
  FINISHED: "Finished",
  ENABLE: "Enable",
  DISABLE: "Disable",
  BACK: "Back",
  UNTITLED: "Untitled",
  URL_PLACEHOLDER: "http://example.com",
  HELP: "Help",
  MODAL__NOT_SHOW_LABEL: "Do not show again",
  CONTACT_US: "contact us",
  REFRESH: "Refresh",
  IMAGE_REQUIREMENTS:
    "#{format}, resolution #{minResolution} or greater, aspect ratio #{aspectRatio}",
  NO_DATA: "no data",
  CHOOSE_ACTION: "Choose action",
  READONLY: "Readonly",
  PREDEFINED: "Predefined",
  MANAGER: "Manager",
  ORGANIZATIONS: "Organizations",
  QUERY_NAME: "Query name",
  DATE_SPAN: "YYYY-MM-DD,YYYY-MM-DD",
  LIGHT_MODE: "Light mode",
  DARK_MODE: "Dark mode",
  UNSAVED_CHANGES_WARNING_MODAL_HEADER: "Unsaved changes",
  UNSAVED_CHANGES_WARNING_MODAL_CANCEL: "Continue without saving filter",
  UNSAVED_CHANGES_WARNING_MODAL_ACCEPT: "Add unsaved filter",
  EXTERNAL: "External",
  REFRESH_TOKEN: "Refresh token",
  // unauthorized
  UNAUTHORIZE_HEADER_TEXT: "Authentication Error",
  UNAUTHORIZE_MESSAGE:
    "Encountered an issue with your authentication token. Please click the button below to renew your token and continue accessing the app.",
  // snap
  SNAP_APP__CREATOR_ROUTE_NAME: "App Creator",
  SNAP_APP__EDIT_MONETIZATION_GUIDE: `
      <p><em>Free</em> means that the app only contains free content that is not monetized in any way</p>
      <p>
        <em>Advertisement</em> means you want to enable video advertising.
      </p>
      <p>
        Vewd App template includes a VAST 2.0 compliant player.
        <br />
        On this window you can configure the frequency and placement (pre- and mid-roll) of the ad insertion points.
      </p>
      <p>
        In order to connect your app with an ad server/network via a VAST
        tag, please contact our support team at #{link} and they
        will be able to assist you. Please note that we do not provide
        advertising services, but we can connect you with one of our
        monetization partners if needed.
      </p>`,
  // vewd os
  VEWD_OS__ROUTE_NAME: "Vewd Os",

  // guidelines
  GUIDELINES__ROUTE_NAME: "Guidelines",

  // side menu
  SIDE_MENU__DASHBOARD_ITEM: "Dashboard",
  SIDE_MENU__APPLICATIONS_ITEM: "Applications",
  SIDE_MENU__MY_APPS_ITEM: "My apps",
  SIDE_MENU__MODERATION_APPS_ITEM: "Moderation app list",
  SIDE_MENU__APPS_TO_DISTRIBUTE_ITEM: "Apps to distribute",
  SIDE_MENU__SUBMIT_APP_ITEM: "Submit new app",
  SIDE_MENU__AVAILABLE_APPS_ITEM: "Available apps",
  SIDE_MENU__APP_CREATOR_ITEM: "App creator",
  SIDE_MENU__APP_MANIFESTS_ITEM: "App Manifests",
  SIDE_MENU__APP_MANIFESTS_LIST_ITEM: "Manifests list",
  SIDE_MENU__APP_MANIFESTS_CREATE_ITEM: "Create Manifest",
  SIDE_MENU__CREATE_APP_ITEM: "Create App",
  SIDE_MENU__DEVICE_ITEM: "Devices",
  SIDE_MENU__ADD_DEVICE_MODEL_ITEM: "Add device model",
  SIDE_MENU__DEVICE_MODELS_ITEM: "Device models",
  SIDE_MENU__DEVICE_FILTERS_AND_QUERIES_ITEM: "Queries",
  SIDE_MENU__MY_DEVICE_MODELS_ITEM: "My device models",
  SIDE_MENU__DEVICE_INVENTORY_ITEM: "Device inventory",
  SIDE_MENU__DEVICE_PROCESSING_QUEUE_ITEM: "Processing queue",
  SIDE_MENU__DEVICE_COMMANDS_ITEM: "Device Commands",
  SIDE_MENU__DEVICE_PAIRING_ITEM: "Device Pairing",
  SIDE_MENU__DISTRIBUTION_ITEM: "Distribution",
  SIDE_MENU__DEFAULT_DISTRIBUTION_ITEM: "Default Distribution",
  SIDE_MENU__CUSTOM_DISTRIBUTION_ITEM: "Custom Distribution",
  SIDE_MENU__DISTRIBUTION_ADMIN_ITEM: "Distribution admin",
  SIDE_MENU__ASSETS_ITEM: "Assets",
  SIDE_MENU__PROMOTION_ITEM: "Promotion",
  SIDE_MENU__PROMOTIONS_ITEM: "Promotions",
  SIDE_MENU__PROMOTIONS_ADMIN_ITEM: "Predefined Promotions",
  SIDE_MENU__CAMPAIGNS_ITEM: "Campaigns",
  SIDE_MENU__CAMPAIGNS_ADMIN_ITEM: "Predefined Campaigns",
  SIDE_MENU__SCHEDULE_ITEM: "Schedule",
  SIDE_MENU__SCHEDULE_ADMIN_ITEM: "Predefined Schedule",
  SIDE_MENU__PROMOTION_TYPES_ITEM: "Promotion types",
  SIDE_MENU__USERS_ITEM: "Users",
  SIDE_MENU__USER_LIST_ITEM: "User list",
  SIDE_MENU__USERS_ADMIN_ITEM: "Users admin",
  SIDE_MENU__INVITE_USER_ITEM: "Invite user",
  SIDE_MENU__ORGANIZATION_ITEM: "Organization",
  SIDE_MENU__ORGANIZATIONS_ITEM: "Organizations",
  SIDE_MENU__MY_ORGANIZATION_ITEM: "My organization",
  SIDE_MENU__CREATE_ORGANIZATION_ITEM: "Create organization",
  SIDE_MENU__COLLAPSE_EXPAND_HINT: "collapse/expand menu",
  SIDE_MENU__UPDATE_ITEM: "Update",
  SIDE_MENU__SOFTWARE_PACKAGES_ITEM: "Software Packages",
  SIDE_MENU__SOFTWARE_PACKAGES_PROCESSING_QUEUE_ITEM: "Processing queue",
  SIDE_MENU__CREATE_UPDATE_JOB: "Create new Update Job",
  SIDE_MENU__UPDATE_JOB_LIST_ITEM: "Update Jobs",

  // 404 page not found
  PAGE_404__HEADER: "Error 404",
  PAGE_404__IMAGE_ALT: "Error 404",
  PAGE_404__CONTENT_HEADER: "Error 404",
  PAGE_404__LINE_1:
    "Oh Snap! Looks like the page you are looking for doesn't exist...",
  PAGE_404__LINE_2: "B.T.W. it doesn't mean we didn't release!",

  // error messages
  ERROR__START_DATE_PAST: "Start date may not be in the past",
  ERROR_SERVER_VALIDATION:
    "This field will be re-validated after sent to server",
  ERROR__END_DATE: "End date may not be earlier than start date",
  ERROR__FIELD_REQUIRED: "Field is required",
  ERROR__FIELD_LARGER_THAN: "Value must be larger than #{value}",
  ERROR__INVALID_EMAIL: "Field should be a valid email",
  ERROR__INVALID_JSON: "Field should be a valid JSON",
  ERROR__INVALID_PHONE: "Field should be a valid phone number",
  ERROR__INVALID_URI_SYMBOLS:
    "Field should contain only characters allowed in URI strings",
  ERROR__INVALID_URL:
    "Field should be a valid URL, something like: http://example.com",
  ERROR__INVALID_ASSET_VERSION:
    "Field should be a valid version, something like: 1.0.0",
  ERROR__ONE_ELEMENT_REQUIRED: "Field should have at least one element",
  ERROR__MIN_VALUE: "Min value: #{min}",
  ERROR__MAX_VALUE: "Max value: #{max}",
  ERROR__NO_LONGER_THAN: "Value shouldn't be longer than #{max} chars.",
  ERROR__INVALID_SLUG:
    "Slug can contain only letters, numbers, underscore (_), hyphen (-) and exclamation mark (!)",
  ERROR__INVALID_DEVICE_SLUG:
    "Slug should follow '<OEM>-<modelname>' format, where <OEM> can include only characters: A-Z, a-z, 0-9 and <modelname> can't include \"(),/:;<=>?@[]{}",
  ERROR__UNDEFINED_FIELD_FROM_BACKEND:
    'Form field for "#{fieldName}" returned from server is not defined',
  ERROR__IMAGE_FILE_TOO_BIG:
    "Uploaded file size is too big, maximum size is #{maxSize} kilobytes",
  ERROR__IMAGE_DIMENSIONS_TOO_SMALL:
    "Invalid dimensions of file, it should be greater or equal to #{minWidth}x#{minHeight}.",
  ERROR__IMAGE_DIMENSIONS_TOO_BIG:
    "Invalid dimensions of file, it should be smaller or equal to #{maxWidth}x#{maxHeight}.",
  ERROR__IMAGE_INVALID_RATIO:
    "Invalid aspect ratio of file, it should be #{ratio}",
  ERROR__INVALID_TYPE:
    "Invalid type of #{variableName} - only #{typesStr} supported",
  ERROR__NOT_IN_TIME_RANGE:
    "The value must be between #{timeMin} and #{timeMax}.",

  // generic error messages
  GENERIC_ERROR__ALREADY_EXISTS_ERROR:
    "The resource you tried to create already exists. Please create a different resource or edit the original one.",
  GENERIC_ERROR__FIELD_ERROR: "The entered value is invalid.",
  GENERIC_ERROR__FORBIDDEN_ERROR:
    "The resource you searched for is not accessible with your permissions. Please turn back.",
  GENERIC_ERROR__NETWORK_ERROR:
    "Unable to connect to the service. Please check your Internet connection.",
  GENERIC_ERROR__NO_ERROR:
    "There seems to be no error. Please make sure that the request was successful.",
  GENERIC_ERROR__NOT_FOUND_ERROR:
    "The resource you searched for does not exist. Please turn back.",
  GENERIC_ERROR__REQUEST_ERROR:
    "There seems to be an issue with your request. Please try again.",
  GENERIC_ERROR__SERVER_ERROR:
    "There seems to be an issue with the service. Please try again.",
  GENERIC_ERROR__USER_INPUT_ERROR:
    "There seems to be an issue with the entered values. Please try to correct them.",

  // Permissions/fallbacks
  PERMISSIONS__NO_ORGANIZATION_FALLBACK_HEADER: "your organization",
  PERMISSIONS__NO_ORGANIZATION_FALLBACK_INFO:
    "Before you can perform organization-related action, we have to ask you, to fill up your organization details. It's simple, just click the button below, and follow instructions.",
  PERMISSIONS__NO_ORGANIZATION_FALLBACK_BUTTON: "create organization",
  PERMISSIONS__NO_PERMISSIONS_FALLBACK_HEADER: "Sorry, wrong way...",
  PERMISSIONS__NO_PERMISSIONS_FALLBACK_INFO:
    "It seems that you don't have an access to this area.",

  // search bar
  SEARCH_BAR__INCLUDE_LABEL: "Include",
  SEARCH_BAR__EXCLUDE_LABEL: "Exclude",
  SEARCH_BAR__EXCLUDE_SUGGESTION_WARNING: "exclude",
  SEARCH_BAR__FILTER_TOOLTIP_PART_1: "Type",
  SEARCH_BAR__FILTER_TOOLTIP_PART_2:
    "to use filters and press enter to apply them",
  SEARCH_BAR__EXCLUDE_FILTER_TOOLTIP_PART_2: "to use filters or",
  SEARCH_BAR__EXCLUDE_FILTER_TOOLTIP_PART_3:
    "to exclude filters and press enter to apply them",
  SEARCH_BAR__FILTERS: "Filters",
  SEARCH_BAR__FILTERS_ERROR:
    "There was an issue with fetching available filters",
  SEARCH_BAR__SUGGESTIONS_ERROR: "There was an issue with fetching suggestions",

  // Countries
  CONTINENTS__AFRICA: "Africa",
  CONTINENTS__ANTARCTICA: "Antarctica",
  CONTINENTS__ASIA: "Asia",
  CONTINENTS__EUROPE: "Europe",
  CONTINENTS__NORTH_AMERICA: "North America",
  CONTINENTS__OCEANIA: "Oceania",
  CONTINENTS__SOUTH_AMERICA: "South America",
  CONTINENTS__WORLD: "World",
  CONTINENTS__WORLDWIDE_ALL_SELECTED: "Worldwide (all)",
  CONTINENTS__ALL_SELECTED: "all",

  // Target devices
  TARGET_DEVICES__DEVICE_COUNT: "#{deviceCount} devices found",
  TARGET_DEVICES__ELEMENT_COUNT: "#{count} elements",

  // Upload to AWS modal
  UPLOADER_AWS_MODAL_EXTERNAL_FILE: "Upload external file",
  UPLOADER_AWS_MODAL_FILE_EXTENSION: " File extension",
  UPLOADER_AWS_MODAL_LINK_FROM_WEB:
    "If you want to provide an external URL where the file is available, ",
  UPLOADER_AWS_MODAL_LINK_FROM_WEB_BUTTON: "click here.",
  UPLOADER_AWS_MODAL_LINK_FROM_WEB_URL: "URL:",
  UPLOADER_AWS_MODAL_LINK_FROM_WEB_INPUT_PLACEHOLDER: "Insert the URL here",
  UPLOADER_AWS_MODAL_ERROR:
    "Cannot connect to that URL. Please check your Internet connection and try again.",
  UPLOADER_AWS_MODAL_EXTERNAL_URL_VALIDATION_ERROR:
    "Field should be a valid URL, something like https://example.com",

  // Apps list
  APP_LIST_MY__ROUTE_NAME: "My Apps",
  APP_LIST_MY__PAGE_HEADER: "My applications",
  APP_LIST_MODERATION__ROUTE_NAME: "Moderation app list",
  APP_LIST_MODERATION__PAGE_HEADER: "Moderation app list",
  APP_LIST_AVAILABLE__ROUTE_NAME: "Available apps",
  APP_LIST_AVAILABLE__PAGE_HEADER: "Available apps",
  APP_LIST__TAB_DETAILS: "App Details",
  APP_LIST__TAB_CERTIFICATION: "Certification",
  APP_LIST__TAB_MODERATION: "Moderation",
  APP_LIST__TAB_TECHNICAL_DETAILS: "Technical Details",
  APP_LIST__TAB_CERTIFICATION_AND_MODERATION: "Certification & Moderation",
  APP_LIST__COLUMN_ICON: "Icon",
  APP_LIST__COLUMN_NAME: "Name",
  APP_LIST__COLUMN_CATEGORY: "Category",
  APP_LIST__COLUMN_OWNER: "Application owner",
  APP_LIST__COLUMN_DATE_SUBMITTED: "Date Submitted",
  APP_LIST__COLUMN_SUBMISSION_STATUS: "Submission Status",
  APP_LIST__COLUMN_SUBMISSION_STATUS_WAITING_FOR_REVIEW: "Waiting for review",
  APP_LIST__COLUMN_SUBMISSION_STATUS_IN_REVIEW: "In review",
  APP_LIST__COLUMN_SUBMISSION_STATUS_REJECTED: "Rejected",
  APP_LIST__COLUMN_SUBMISSION_STATUS_FINISHED: "Finished",
  APP_LIST__COLUMN_SUBMISSION_STATUS_NEW: "New",
  APP_LIST__COLUMN_SUBMISSION_STATUS_DRAFT: "Draft",
  APP_LIST__COLUMN_SUBMISSION_STATUS_CREATED: "Created",
  APP_LIST__COLUMN_DISTRIBUTION_STATUS: "Distribution Status",
  APP_LIST__COLUMN_DISTRIBUTION_STATUS_READY_FOR_DISTRIBUTION:
    "Ready for Distribution",
  APP_LIST__COLUMN_DISTRIBUTION_STATUS_LIVE: "Live",
  APP_LIST__COLUMN_DISTRIBUTION_STATUS_ON_HOLD: "On Hold",
  APP_LIST__COLUMN_MESSAGES: "Messages",
  APP_LIST__COLUMN_CERTIFICATION: "Certification",
  APP_LIST__COLUMN_CONTENT_MANAGER: "Content Manager",
  APP_LIST__COLUMN_TARGET_STATUS: "Target Status",
  APP_LIST__COLUMN_DISTRIBUTION: "Distribution",
  APP_LIST__COLUMN_MODERATION_STATUS: "Moderation Status",
  APP_LIST__COLUMN_MODERATOR: "Moderator",
  APP_LIST__COLUMN_PRIORITY: "Priority",
  APP_LIST__COLUMN_APP_TYPE: "App Type",
  APP_LIST__COLUMN_VIDEO_SPEC: "Video Spec",
  APP_LIST__COLUMN_WARNINGS: "Warnings",
  APP_LIST__COLUMN_AVAILABLE_SINCE: "Available since",
  APP_LIST__COLUMN_DEFAULT_DISTRIBUTION_LINE_1: "Default",
  APP_LIST__COLUMN_DEFAULT_DISTRIBUTION_LINE_2: "distribution",
  APP_LIST__PREMIUM_OWNER_TOOLTIP:
    "This application owner has #{organizationPlan} status",
  APP_LIST__ACTION_MENU_EDIT: "Edit Details",
  APP_LIST__ACTION_MENU_UPDATE: "Update App",
  APP_LIST__ACTION_MENU_DETAILS: "Show details",
  APP_LIST__ACTION_MENU_MESSAGE_CENTER: "Message center",
  APP_LIST__ACTION_MENU_APPROVE_FOR_DEFAULT_PLAN: "Approve for default plan",
  APP_LIST__ACTION_MENU_REJECT_FOR_DEFAULT_PLAN: "Reject for default plan",
  APP_LIST__ACTION_MENU_MANAGE_APP_IN_PLANS: "Manage app in custom plans",
  APP_LIST__ACTION_MENU_MANAGE_APPS_IN_PLANS: "Manage apps in custom plans",
  APP_LIST__ACTION_MENU_CREATE_CUSTOM_PLAN:
    "Create new Custom Distribution Plan",

  // App list: distribution
  APP_LIST_DISTRIBUTION__ROUTE_NAME: "Apps to distribute",
  APP_LIST_DISTRIBUTION__PAGE_HEADER: "Apps to distribute",
  APP_LIST_DISTRIBUTION__APPROVE_FOR_DEFAULT_SUCCESS:
    'Succesfully approved app "#{appName}" for default plan',
  APP_LIST_DISTRIBUTION__APPROVE_FOR_DEFAULT_ERROR:
    "Approving for default plan failed",
  APP_LIST_DISTRIBUTION__REJECT_FOR_DEFAULT_SUCCESS:
    'Succesfully rejected app "#{appName}" from default plan',
  APP_LIST_DISTRIBUTION__REJECT_FOR_DEFAULT_ERROR:
    "Rejecting for default plan failed",
  APP_LIST_DISTRIBUTION__STATUS_INCONSISTENT:
    "Your change is queued. It might take a moment until it's visible on your devices.",
  APP_LIST_DISTRIBUTION__REJECTED: "Rejected by Moderator",
  APP_LIST_DISTRIBUTION__REJECTED_POPUP:
    "Unfortunately this App has been rejected by moderator.",
  APP_LIST_DISTRIBUTION__ON_HOLD: "On Hold",
  APP_LIST_DISTRIBUTION__ON_HOLD_POPUP:
    "Distribution of this App has been put On Hold on some of your Devices.",
  APP_LIST_DISTRIBUTION__MANAGE_APPS_SUCCESS_ONE:
    "Successfully changed plans for 1 application",
  APP_LIST_DISTRIBUTION__MANAGE_APPS_SUCCESS_MANY:
    "Successfully changed plans for #{appsCount} applications",
  APP_LIST_DISTRIBUTION__MANAGE_APPS_MODAL_TITLE: "Manage apps in custom plans",
  APP_LIST_DISTRIBUTION__MANAGE_APPS_MODAL_APPS_SELECTED_MANY:
    "Applications selected (#{count}):",
  APP_LIST_DISTRIBUTION__MANAGE_APPS_MODAL_HEADER:
    "Select custom plan from the list below",
  APP_LIST_DISTRIBUTION__MANAGE_APPS_MODAL_PLACEHOLDER: "Search custom plan",
  APP_LIST_DISTRIBUTION__MANAGE_APPS_MODAL_STATUS: "Status",
  APP_LIST_DISTRIBUTION__MANAGE_APPS_MODAL_INCLUDED_APPS: "Included apps",
  APP_LIST_DISTRIBUTION__MANAGE_APPS_MODAL_INCLUDED_APPS_VALUE:
    "#{selectedCount} of #{totalApps}",
  APP_LIST_DISTRIBUTION__MANAGE_APPS_MODAL_TOOLTIP_HEADER: "Checkbox status",
  APP_LIST_DISTRIBUTION__MANAGE_APPS_MODAL_TOOLTIP_CHECKED:
    "Enable all selected applications in this custom plan.",
  APP_LIST_DISTRIBUTION__MANAGE_APPS_MODAL_TOOLTIP_INDETERMINATE:
    "Some of the selected applications are already added to this custom plan. Select to disable them.",
  APP_LIST_DISTRIBUTION__MANAGE_APPS_MODAL_TOOLTIP_UNCHECKED:
    "Disable all selected applications in this custom plan.",
  APP_LIST_DISTRIBUTION__MANAGE_APPS_MODAL_CHANGED_COUNT:
    "Added #{addedCount} / Removed #{removedCount}",
  APP_LIST_DISTRIBUTION__BULK_ACTIONS_PLACEHOLDER: "Choose action",

  // App submit: introduction tab
  APP_SUBMIT__PAGE_HEADER: "Submit New Application",
  APP_SUBMIT__THANK_YOU_FOR_SUBMITTING:
    "Thank you for submitting to Vewd. Before you begin please read",
  APP_SUBMIT__SUBMIT_NOTES_LINK: "these notes",
  APP_SUBMIT__THIS_IS_TEST_ONLY_APP:
    "This is only an application for private use",
  APP_SUBMIT__THIS_IS_TEST_ONLY_APP_DESC:
    "If you select this option, <strong>your app will not be moderated or published on real Device</strong>. This is useful, when you want to test your Application on your private Device.",
  APP_SUBMIT__ADDITIONAL_OPTIONS: "Additional options",
  APP_SUBMIT__APPLICATION_NAME: "Application name",
  APP_SUBMIT__APPLICATION_NAME_TOOLTIP:
    "A title for your app, used only in the Back Office system. We will ask for the title that users see in the Appstores and on devices in the next step.",
  APP_SUBMIT__APPLICATION_TYPE: "Application type",
  APP_SUBMIT__APPLICATION_TYPE_HTML_TOOLTIP:
    "HTML5 is used as the standard deployment application type across all Vewd products",
  APP_SUBMIT__APPLICATION_TYPE_HBBTV_TOOLTIP:
    "HbbTV (Hybrid Broadcast Broadband TV) are hybrid digital TV apps used by broadcasters for specific supported markets",

  // App submit: basic details tab
  APP_EDIT__ERROR_PAGE_HEADER: "App edit",
  APP_EDIT__ERROR_PAGE_DEFAULT_MESSAGE: "Error fetching application data",
  APP_EDIT__ERROR_PAGE_APP_IS_IN_REVIEW:
    "Cannot edit app that is being reviewed",
  APP_EDIT_BASIC__TAB: "Basic details",
  APP_EDIT__GENERAL_SECTION_NAME: "General information about the application",
  APP_EDIT__ANDROID_PROFILES_SECTION_NAME: "Android Profiles",
  APP_EDIT__LINUX_PROFILES_SECTION_NAME: "Linux Profiles",
  APP_EDIT__PROFILE_NAMES_MUST_BE_UNIQUE_ERROR:
    "Profiles names have to be unique.",
  APP_EDIT__PROFILE_ERROR: "There is something wrong with one of the profiles",
  APP_EDIT__URL_LABEL: "Application URL",
  APP_EDIT__URL_LABEL_TOOLTIP:
    "URL to the HTML Application. Apps that are submitted to the Vewd Cloud are hosted on the App Owner side. Make sure Application URL is accessible from the public network.",
  APP_EDIT__ID_LABEL: "Application ID",
  APP_EDIT__ID_TOOLTIP:
    "Application ID is an automatically generated, unique identifier for your App.",
  APP_EDIT__CATEGORY_TOOLTIP:
    "Select the category that suits the best with Your App. That helps App Distributors position your App in the Appstores and Device UI.",
  APP_EDIT__DESCRIPTION_TITLE_TOOLTIP:
    "App Title for the Appstores and Device UI.",
  APP_EDIT__DESCRIPTION_TOOLTIP:
    "A short description of your app that is appropriate for display on a TV.",
  APP_EDIT__PROFILE_TABLE_HEADER: "Profile",
  APP_EDIT__PROFILE_NAME_LABEL: "Profile name",
  APP_EDIT__PROFILE_ACTION_LABEL: "Action",
  APP_EDIT__PROFILE_NATIVE_ID_LABEL: "Native ID",
  APP_EDIT__PROFILE_PACKAGE_LABEL: "Package",
  APP_EDIT__PROFILE_CLASS_LABEL: "Class",
  APP_EDIT__PROFILE_NO_PROFILE: "No profile created",
  APP_EDIT__PROFILE_ACTION_ADD: "Add",
  APP_EDIT__PROFILE_ACTION_ADD_PROFILE: "Add profile",
  APP_EDIT__PROFILE_ACTION_EDIT_PROFILE: "Edit profile",
  APP_EDIT__LANGUAGE_SET_AS_DEFAULT: "Set language as default",
  APP_EDIT__NEW_TRANSLATION: "New translation",
  APP_EDIT__NAME_TOOLTIP: "Edit application name",
  APP_EDIT__ADD_NEW_ITEM: "Add new item",
  APP_EDIT__SUPPORT_EMAIL_SECTION_NAME: "Support emails",
  APP_EDIT__SUPPORT_EMAIL_LABEL: "Support email",
  APP_EDIT__SUPPORT_EMAIL_TOOLTIP:
    "You can specify support email exclusively for this App. Otherwise you can use your organization support email.",
  APP_EDIT__ADD_AN_EMAIL_PLACEHOLDER: "Add an e-mail",
  APP_EDIT__MEDIA_SECTION_NAME: "Media",
  APP_EDIT__THUMBNAIL_UPLOADER_LABEL: "Application Thumbnail",
  APP_EDIT__THUMBNAIL_UPLOADER_TOOLTIP:
    "Thumbnail should be an attractive, graphical representation of your app. Thumbnail should include your app’s name. It's the most popular Asset used in Appstores and Device UI.",
  APP_EDIT__BILLBOARD_UPLOADER_LABEL: "Application Billboard",
  APP_EDIT__BILLBOARD_UPLOADER_TOOLTIP:
    "Billboard should be an attractive, graphical representation of the content of your application. Treat this element as an advertisement to encourage the user to use your application.",
  APP_EDIT__ICON_BIG_UPLOADER_LABEL: "Application Icon Big",
  APP_EDIT__ICON_BIG_UPLOADER_TOOLTIP:
    "Big Icons are used mostly for the promotional purposes and print materials. It can be the same style as a small icon enriched with additional details.",
  APP_EDIT__ICON_SMALL_UPLOADER_LABEL: "Application Icon Small",
  APP_EDIT__ICON_SMALL_UPLOADER_TOOLTIP:
    "Small Icons are usually shown next to the App Title, or Description. Make sure that content of the icon will be visible on the TV from 2-3 meters.",
  APP_EDIT__SCREENSHOTS_UPLOADER_LABEL: "Application Screenshots",
  APP_EDIT__SCREENSHOTS_UPLOADER_TOOLTIP:
    "Screenshots should be graphical representation of the content of your application. Treat this element as a way to showcase features available within the application.",
  APP_EDIT__TECHNICAL_INFO_SECTION_NAME: "Technical info / Video formats",
  APP_EDIT__TECHNICAL_INFO_VIDEO_FORMATS_ERROR:
    "At least one video format must be checked",
  APP_EDIT__VIDEO_TYPES_LABEL: "Video type",
  APP_EDIT__VIDEO_TYPES_ERROR: "At least one video type must be checked",
  APP_EDIT__VIDEO_FORMATS_LABEL: "Video format",
  APP_EDIT__ADAPTIVE_STREAMINGS_LABEL: "Adaptive Bitrate Streaming (ABR)",
  APP_EDIT__DRMS_LABEL: "DRM",
  APP_EDIT__MY_APP_HAS_VIDEO_LABEL: "My application contains video",
  APP_EDIT__MY_APP_HAS_VIDEO_TOOLTIP:
    "Select this option if your App contains video playback.",
  APP_EDIT__MY_APP_HAS_LIVE_VIDEO_LABEL: "Live video content",
  APP_EDIT__MY_APP_HAS_VOD_LABEL: "Video on demand (VOD)",
  APP_EDIT__DRAFT_SAVE_SUCCESS: "Draft saved successfully.",
  APP_EDIT__DRAFT_SAVE_WARNING:
    "Draft saved successfully. Not all fields are filled correctly. You need to correct them before submitting the app.",
  APP_EDIT__DRAFT_SAVE_BUTTON: "Save draft",
  APP_EDIT__DRAFT_DISCARD_BUTTON: "Discard draft",
  APP_EDIT__SUBMIT_ERROR:
    "Submission failure. Please, correct errors before proceeding.",
  APP_EDIT__LANGUAGE_TOOLTIP:
    "Select Language for Title and Description. You can Add more translations on the left column.",

  // App submit: info for moderators tab
  APP_EDIT_FOR_MODERATORS__TAB: "Info for moderators",
  APP_EDIT_FOR_MODERATORS__SECTION_NAME:
    "Detailed description for moderators in English",
  APP_EDIT_FOR_MODERATORS__DESCRIPTION_LABEL:
    "Detailed description for moderators in English",
  APP_EDIT_FOR_MODERATORS__DESCRIPTION_LABEL_TOOLTIP:
    "Describe basic App functionality and features.",
  APP_EDIT_FOR_MODERATORS__ADDITIONAL_COMMENTS_SECTION_NAME:
    "Additional comments",
  APP_EDIT_FOR_MODERATORS__ADDITIONAL_COMMENTS_LABEL: "Additional comments",
  APP_EDIT_FOR_MODERATORS__ADDITIONAL_COMMENTS_LABEL_TOOLTIP:
    "If there is anything that can help Moderator when testing, or anything that Moderator should check during the moderation process, please describe it here.",
  APP_EDIT_FOR_MODERATORS__CHANGELOG_SECTION_NAME: "Changelog",
  APP_EDIT_FOR_MODERATORS__CHANGELOG_MAJOR_CHANGE_LABEL:
    "Major - App rewritten, new code deployed",
  APP_EDIT_FOR_MODERATORS__CHANGELOG_MINOR_CHANGE_LABEL:
    "Minor - Bug fixes, no major changes",
  APP_EDIT_FOR_MODERATORS__CHANGELOG_ASSETS_CHANGE_LABEL:
    "Changes to description, app icons, without changes to app",
  APP_EDIT_FOR_MODERATORS__APP_CHANGELOG_DESCRIPTION_LABEL:
    "Description of changes",
  APP_EDIT_FOR_MODERATORS__APP_CHANGELOG_DESCRIPTION_LABEL_TOOLTIP:
    "Describe changes in application in your own words.",
  APP_EDIT_FOR_MODERATORS__CHANGELOG_NOT_INITIAL_VERSION_WARNING:
    "This isn't initial version of this app. Choose changelog scope",

  // App submit: markets and monetization tab
  APP_EDIT_MARKETS__TAB: "Markets and monetization",
  APP_EDIT_MARKETS__MONETIZATION_SECTION_NAME: "Monetization",
  APP_EDIT_MARKETS__APP_HAS_NO_REVENUE: "Application does not generate revenue",
  APP_EDIT_MARKETS__APP_HAS_REVENUE: "Application generates revenue",
  APP_EDIT_MARKETS__APP_HAS_REVENUE_TRANSACTIONS: "Transactions",
  APP_EDIT_MARKETS__APP_HAS_REVENUE_SUBSCRIPTIONS: "Subscriptions",
  APP_EDIT_MARKETS__APP_HAS_REVENUE_ADVERTISEMENT: "Advertisement",
  APP_EDIT_MARKETS__APP_HAS_REVENUE_OTHER_PLACEHOLDER: "tell us more",
  APP_EDIT_MARKETS__NON_PREMIUM_WARNING:
    "You are submitting an application that directly generates revenue, and you selected to distribute it in Vewd Catalog. This requires your account to be upgraded. Please finish submitting the application and contact Business Development team at #{link} to upgrade your account.",
  APP_EDIT_MARKETS__MARKET_MAIN_SECTION_NAME: "Markets",
  APP_EDIT_MARKETS__APP_DESIGNED_FOR_MARKETS:
    "My app was designed for specific markets.",
  APP_EDIT_MARKETS__APP_DESIGNED_FOR_MARKETS_TOOLTIP:
    "If your app has been designed for specific markets in mind, please select them now. Otherwise you can select Worldwide.",
  APP_EDIT_MARKETS__SPECIFIC_COUNTRIES: "Specific Countries",
  APP_EDIT_MARKETS__APP_ALSO_AVIALABLE_IN: "My app can also be available:",
  APP_EDIT_MARKETS__APP_ALSO_AVIALABLE_IN_TOOLTIP:
    "Select regions or countries where your App can be safely published. Make sure content of your App does not violate law for selected countries.",
  APP_EDIT_MARKETS__ONLY_IN_SPECIFIC_COUNTRIES: "Only in Specific Countries",
  APP_EDIT_MONETIZATION__REVENUE_TYPE_IS_REQUIRED:
    "This field is required. Please check at least one option",

  // App submit: certification tab
  APP_EDIT_CERTIFICATION__TAB: "Certification, distribution & contract",
  APP_EDIT_CERTIFICATION__SECTION_NAME: "Choose a program",
  APP_EDIT_CERTIFICATION__HEADER_LINE_1:
    "Vewd's open platform approach solves the problem faced by most developers of OTT services by providing a certification program that helps to easily build and deploy OTT apps that will work instantly across the Vewd-enabled device ecosystem.",
  APP_EDIT_CERTIFICATION__HEADER_LINE_2:
    "is a developer program with a complete suite of tools, specifications, and documentation that helps you certify, examine and distribute HTML5 based applications across Vewd-enabled devices.",
  APP_EDIT_CERTIFICATION__TERMS_SECTION_NAME: "Terms & conditions",
  APP_EDIT_CERTIFICATION__APP_DISTRIBUTE_AGREE:
    "I allow Vewd to Distribute my Application",
  APP_EDIT_CERTIFICATION__I_VE_READ: "I have read",
  APP_EDIT_CERTIFICATION__ACCEPTANCE: "Acceptance",
  APP_EDIT_CERTIFICATION__CRITERIA: "criteria",
  APP_EDIT_CERTIFICATION__AGREEMENT: "Agreement",
  APP_EDIT_CERTIFICATION__AGREEMENT_TOOLTIP:
    "Before submiting an App you must read Terms & Conditions and Acceptance Criteria. Check all options below and you are ready to Submit.",
  APP_EDIT_CERTIFICATION__ACCEPT_TERMS_AND_CONDITIONS:
    "I have read and I Agree to the Terms & Conditions of this service",
  APP_EDIT_CERTIFICATION__DISTRIBUTION_TOOLTIP:
    "Select this option to allow Vewd to distribute your App. Note - Vewd controls distribution for some Distributor partners. By allowing Vewd to distribute your App, you increase it's target range.",
  APP_EDIT_CERTIFICATION__VEWD_CATALOGUE: "DISTRIBUTE - Vewd Catalog",
  APP_EDIT_CERTIFICATION__VEWD_CATALOGUE_DESCRIPTION:
    "Allow Vewd to distribute Your application",
  APP_EDIT_CERTIFICATION__VEWD_CATALOGUE_DESCRIPTION_HTML:
    "Allow Vewd to certify and distribute Your application",
  APP_EDIT_CERTIFICATION__VEWD_CERTIFICATION_PROGRAM:
    "CERTIFY - Vewd Certification Program",
  APP_EDIT_CERTIFICATION__VEWD_CERTIFICATION_PROGRAM_DESCRIPTION:
    "Allow Vewd to certify your application according to the Vewd Certify for Apps(VCA) specification, so the app becomes compliant with Vewd certified platforms. You will have to sign your own Commercial & Distribution agreements directly with OEMs/ Operators",
  APP_EDIT_CERTIFICATION__EXAMINE: "EXAMINE - Basic validation",
  APP_EDIT_CERTIFICATION__EXAMINE_DESCRIPTION:
    "Allow Vewd to apply basic validation of the app for a given project in coordination with the Vewd content manager",
  APP_EDIT_CERTIFICATION__EXAMINE_ERROR:
    "The EXAMINE program is no longer available.",
  APP_EDIT_CERTIFICATION__SIGNATORY_ROLE_WARNING:
    "This action requires the 'Signatory' role in your organization.",

  // App submit: test application
  TEST_APP_EDIT__TAB: "Test application",
  TEST_APP_EDIT__INFORMATION_SECTION_NAME: "Information about the application",
  TEST_APP_EDIT__DESCRIPTION_SECTION_NAME: "Description",
  TEST_APP_EDIT__MEDIA_SECTION_NAME: "Test information",
  TEST_APP_EDIT__SUBMIT_ERROR:
    "Saving app failure. Please, correct errors before proceeding.",
  TEST_APP_EDIT__SUBMIT_SUCCESS:
    'Your changes has been saved successfully. Test Apps can be launched on paired devices, under "Develop" category.',

  // App status
  APP_EDIT_STATUS__PAGE_HEADER: "Application status",
  APP_EDIT_STATUS__TEST_SUCCESS: "Your test application have been saved",
  APP_EDIT_STATUS__EXAMINE_HEADER:
    "You have submitted an application for examine",
  APP_EDIT_STATUS__EXAMINE_CONTENT:
    "Vewd Content team will examine your app submission and get back to you.",
  APP_EDIT_STATUS__DISTRIBUTABLE_HEADER:
    "You have submitted an application for distribution",
  APP_EDIT_STATUS__DISTRIBUTABLE_CONTENT:
    "Your application is now in the testing and moderation phase for distribution.",
  APP_EDIT_STATUS__CERTIFICATION_HEADER:
    "You have submitted an application for certification",
  APP_EDIT_STATUS__CERTIFICATION_CONTENT:
    "Vewd Content team will review your app submission and get back to you.",
  APP_EDIT_STATUS__CERTIFICATION_EMAIL_CONTENT: "Email verification",
  APP_EDIT_STATUS__VERIFY_EMAIL:
    "In the meantime, please verify your email address (#{support_email}) by clicking the link in the email we've just sent to you.",
  APP_EDIT_STATUS__BACK_BUTTON: "Go back to applications list",

  // App types
  APP_TYPE__HTML: "HTML5",
  APP_TYPE__HBBTV: "HbbTV",
  APP_TYPE__NATIVE: "Native",

  // App environments
  APP_ENVIRONMENT__GENERIC: "HTML Generic",
  APP_ENVIRONMENT__APP_STORE: "HTML AppStore",
  APP_ENVIRONMENT__HBBTV: "HbbTV",
  APP_ENVIRONMENT__LINUX: "Native Linux",
  APP_ENVIRONMENT__ANDROID: "Native Android",

  // App details: versions
  APP_DETAILS__ERROR_PAGE_HEADER: "App details",
  APP_DETAILS_VERSION__TAB: "Details",
  APP_DETAILS_VERSION__ACTIVE: "Active",
  APP_DETAILS_VERSION__LATEST: "Latest",
  APP_DETAILS_VERSION__REJECTED: "Rejected",
  APP_DETAILS_VERSION__EDIT_BUTTON: "Edit version",
  APP_DETAILS_VERSION__REJECT_METADATA_MODAL_TITLE: "Reject metadata",
  APP_DETAILS_VERSION__REJECT_METADATA_MODAL_REJECT: "Reject",
  APP_DETAILS_VERSION__REJECT_REASON: "Reject reason",
  APP_DETAILS_VERSION__SECTION_ASSETS: "Assets",
  APP_DETAILS_VERSION__ICON_THUMBNAIL: "Thumbnail",
  APP_DETAILS_VERSION__ICON_BILLBOARD: "Application Billboard",
  APP_DETAILS_VERSION__ICON_BIG: "Icon big (512px)",
  APP_DETAILS_VERSION__ICON_SMALL: "Icon small (128px)",
  APP_DETAILS_VERSION__SCREENSHOTS: "Screenshot",
  APP_DETAILS_VERSION__SECTION_BASIC_DETAILS: "Basic details",
  APP_DETAILS_VERSION__SECTION_BASIC_DETAILS_ADDED: "added #{translation}",
  APP_DETAILS_VERSION__SECTION_BASIC_DETAILS_REMOVED: "removed #{translation}",
  APP_DETAILS_VERSION__SECTION_BASIC_DETAILS_SET_AS_DEFAULT:
    "set #{translation} as default",
  APP_DETAILS_VERSION__COMMENT_FROM_AUTHOR: "Comment from author",
  APP_DETAILS_VERSION__SECTION_RUNDATA: "Rundata",
  APP_DETAILS_VERSION__SECTION_OTHER_DETAILS: "Other details",
  APP_DETAILS_VERSION__SECTION_MONETIZATION: "Monetization",
  APP_DETAILS_VERSION__MONETIZATION_TYPES: "Type of monetization",
  APP_DETAILS_VERSION__SECTION_TECHNICAL_DETAILS: "Technical details",
  APP_DETAILS_VERSION__TECHNICAL_DETAILS_CONTENT: "Video Type",
  APP_DETAILS_VERSION__SECTION_OWNER: "App owner",
  APP_DETAILS_VERSION__ORGANIZATION_ID: "Organization ID",
  APP_DETAILS_VERSION__UPGRADE_ORGANIZATION_TO_PREMIUM: "Upgrade to premium",
  APP_DETAILS_VERSION__LINK_TO_APP: "Link to application",
  APP_DETAILS_VERSION__ANDROID_PROFILES: "Android profiles",
  APP_DETAILS_VERSION__LINUX_PROFILES: "Linux profiles",
  APP_DETAILS_VERSION__SUPPORT_EMAIL: "Support e-mail",
  APP_DETAILS_VERSION__DESC_FOR_MODERATORS: "Description for Moderators",
  APP_DETAILS_VERSION__ADDITIONAL_COMMENTS: "Additional comments from author",
  APP_DETAILS_VERSION__SECTION_MARKETS_FOR: "Markets - designed for",
  APP_DETAILS_VERSION__SECTION_MARKETS_AVAIL: "Markets - available",
  APP_DETAILS_VERSION__NO_TAB_TO_SHOW_ERROR:
    "You do not have permissions to view this version of application data.",
  APP_DETAILS_VERSION__UNEXPECTED_ERROR:
    "An unexpected error occurred when fetching application version data",
  APP_DETAILS_VERSION__DRMS_TITLE: "DRMs",
  APP_DETAILS_VERSION__VIDEO_AVAILABLE_TITLE: "Video content",
  APP_DETAILS_VERSION__VIDEO_FORMATS_TITLE: "Video formats",
  APP_DETAILS_VERSION__VIDEO_TYPE_TITLE: "Video type",
  APP_DETAILS_VERSION__ADAPTIVE_STREAMINGS_TITLE: "Adaptive Streamings",
  APP_DETAILS_VERSION__LIVE_VIDEO: "Live",
  APP_DETAILS_VERSION__VOD: "VOD",
  APP_DETAILS_VERSION__APP_TYPE_TITLE: "App type",
  APP_DETAILS_VERSION__APP_ENVIRONMENT_TITLE: "App environment",
  APP_DETAILS_VERSION__PROFILE: "Profile",
  APP_DETAILS_VERSION__PROFILE_PREVIEW_MODAL_TITLE: "Profile '#{profileName}'",

  // App details: configuration
  APP_DETAILS_MANIFEST__TAB: "Manifest",
  APP_DETAILS_MANIFEST__DETAILS_HEADER: "Manifest",
  APP_DETAILS_MANIFEST__DETAILS_DESCRIPTION:
    'A manifest is an app configuration tool for a rendering client. You can configure how a rendering client environment should behave for a particular app using the tool. Changes made to the manifest will be reflected in the data output from the Content Suite API. To learn more about app manifest and its properties, <a href="#{url}" target="_blank" rel="noopener noreferrer">click here</a>.',
  APP_DETAILS_MANIFEST__CONFIG_HEADER: "Current manifest configuration",
  APP_DETAILS_MANIFEST__CONFIG_NO_DATA:
    "There is no manifest for this application.",
  APP_DETAILS_MANIFEST__CONFIG_PREVIEW_BUTTON: "Show preview",
  APP_DETAILS_MANIFEST__CONFIG_PREVIEW_TITLE: "User Script preview",
  APP_DETAILS_MANIFEST__DISPLAY_IMG_TITLE: "Display image preview",
  APP_DETAILS_MANIFEST__EDIT_HEADER: "Settings",
  APP_DETAILS_MANIFEST__REMOVE_BUTTON: "Remove manifest",
  APP_DETAILS_MANIFEST__REMOVE_MESSAGE:
    "You are about to remove a Manifest. Removed Manifest is unable to restore. Are you sure?",
  APP_DETAILS_MANIFEST__REMOVE_CONFIRM_BUTTON: "Yes, remove",
  APP_DETAILS_MANIFEST__FIELD_BOOL_FALSE: "FALSE",
  APP_DETAILS_MANIFEST__FIELD_BOOL_TRUE: "TRUE",
  APP_DETAILS_MANIFEST__FIELD_DISPLAY_NAME:
    "Application name replacement (for media session)",
  APP_DETAILS_MANIFEST__FIELD_DISPLAY_ICON:
    "Application icon (for media session)",
  APP_DETAILS_MANIFEST__FIELD_ZOOM: "Zoom factor of browser window",
  APP_DETAILS_MANIFEST__FIELD_EMPTY: "",
  APP_DETAILS_MANIFEST__FIELD_TYPE:
    "Allows defining the following window types:" +
    "<ul><li><strong>CSP</strong> - For rendering generic content in a regular web browser environment. This is used the most for 3rd party apps.</li>" +
    "<li><strong>Store</strong> - For rendering Vewd App Store Catalog  apps.</li>" +
    "<li><strong>HbbTV</strong> - For rendering HbbTV apps.</li></ul>",
  APP_DETAILS_MANIFEST__FIELD_TYPE_CSP: "Regular (csp / generic)",
  APP_DETAILS_MANIFEST__FIELD_TYPE_STORE: "TV Store",
  APP_DETAILS_MANIFEST__FIELD_TYPE_HBBTV: "HbbTV",
  APP_DETAILS_MANIFEST__FIELD_RESOLUTION: "The device resolution.",
  APP_DETAILS_MANIFEST__FIELD_TRANSPARENT:
    "TRUE if the window should be transparent, allowing it to look through to windows below.",
  APP_DETAILS_MANIFEST__FIELD_BACKGROUND:
    "The colour to use for the background. A css colour in either one of the following formats:" +
    "<ul><li><code>#rrggbb</code></li>" +
    "<li><code>#rrggbbaa</code></li></ul>",
  APP_DETAILS_MANIFEST__FIELD_USER_AGENT:
    "The user agent to use for loading the application in the window.<br><br>" +
    "<strong>Example:</strong> <code>Mozilla/5.0 (Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/88.0.4324.11 Safari/537.36 OPR/46.0.2207.0 OMI/4.22.0.161.master Model/MyManufacturerName-MyModelName</code>",
  APP_DETAILS_MANIFEST__FIELD_CLOSE_WINDOW:
    "Sets a flag to override the security check that JavaScript <code>window.close()</code> may only be called on windows opened with <code>window.open()</code>. Note that enabling this pref will break a de facto web security standard (see: <a href='https://developer.mozilla.org/en-US/docs/Web/API/Window/close' target='_blank' rel='nofollow noopener noreferrer'>window.close() documentation in MDN</a>). It should only be used on windows running portals where there is a specific request for this functionality.",
  APP_DETAILS_MANIFEST__FIELD_SPATNAV: "TRUE to enable spatial navigation.",
  APP_DETAILS_MANIFEST__FIELD_SPATNAV_VIRTUAL: "Virtual keyboard",
  APP_DETAILS_MANIFEST__FIELD_SPATNAV_OMI:
    "Input method driven by the integration",
  APP_DETAILS_MANIFEST__FIELD_INPUT_METHOD:
    "Input method for the given window:" +
    "<ul><li><strong>Virtual keyboard</strong> (provided by Vewd Core)</li>" +
    "<li><strong>Multitap</strong> (provided by Vewd Core)</li>" +
    "<li><strong>Input method driven by the integration</strong> (usually simple forwarding of the pressed key).</li></ul>",
  APP_DETAILS_MANIFEST__FIELD_HISTORY_BACK:
    "TRUE to instruct the browser to navigate backwards in history when it receives a VK_BACK key event that has not have <code>preventDefault()</code> called. If the history has no further pages, the window will be closed.<br><br>" +
    "FALSE to let the app handle VK_BACK and close itself.",
  APP_DETAILS_MANIFEST__FIELD_REMAPPING:
    "This property instructs the integration to translate key events received by the window as <strong>&lt;from&gt;</strong>. These events will be exposed to the application running in the window (to the javascript code) as key events with a different key code: <strong>&lt;to&gt;</strong>.<br><br>" +
    "<strong>&lt;from&gt;</strong> must be a window level key constant WITHOUT THE “VK_” prefix. Example: “LEFT”, “BACK”, “PLAY”, etc.<br>" +
    "If the <strong>&lt;from&gt;</strong> part is not a window level key constant, the mapping will be ignored.<br><br>" +
    "<strong>&lt;to&gt;</strong> must be a numeric constant.<br>" +
    "Example:<br>" +
    "<blockquote>{<br>" +
    "&nbsp; “BACK”: 8<br>" +
    "}</blockquote>" +
    "The above translates BACK to BACK_SPACE.",
  APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_PLACEHOLDER: "Select key",
  APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_LEFT: "←",
  APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_RIGHT: "→",
  APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_UP: "↑",
  APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_DOWN: "↓",
  APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_ENTER: "Confirm / Select / OK",
  APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_BACK: "Back / Return",
  APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_BLUE: "BLUE",
  APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_RED: "RED",
  APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_GREEN: "GREEN",
  APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_YELLOW: "YELLOW",
  APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_MENU: "Menu",
  APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_0: "0",
  APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_1: "1",
  APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_2: "2",
  APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_3: "3",
  APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_4: "4",
  APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_5: "5",
  APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_6: "6",
  APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_7: "7",
  APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_8: "8",
  APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_9: "9",
  APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_PLAY: "PLAY",
  APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_PAUSE: "PAUSE",
  APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_STOP: "STOP",
  APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_TRACK_NEXT: "NEXT",
  APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_TRACK_PREV: "PREV",
  APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_FAST_FWD: "FF (Fast-Forward)",
  APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_REWIND: "REWIND",
  APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_SUBTITLE: "SUBTITLE",
  APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_INFO: "INFORMATION",
  APP_DETAILS_MANIFEST__FIELD_REMAPPING_VALUE_PLACEHOLDER: "Type key value",
  APP_DETAILS_MANIFEST__FIELD_MIXED_ACTIVE_CONTENT:
    "FALSE to discard the check against the usage of active mixed content. This should *not* be needed if the page declares proper CSP configuration.",
  APP_DETAILS_MANIFEST__FIELD_CORS:
    "FALSE to disable CORS/origin checks on xhr/fetch calls.",
  APP_DETAILS_MANIFEST__FIELD_MIXED_XHR_CONTENT:
    "FALSE to disable protection against XHR to non-https origin from secure origins.",
  APP_DETAILS_MANIFEST__FIELD_MIXED_FETCH_CONTENT:
    "FALSE to disable protection against Fetch to non-https origin from secure origins.",
  APP_DETAILS_MANIFEST__FIELD_CORS_GUARD:
    "FALSE to disable no-cors request header guards for what headers are allowed to send with no-cors.",
  APP_DETAILS_MANIFEST__FIELD_CUSTOM:
    "You can add custom properties for your rendering client if they are not already in the defined properties. Please make sure to add a descriptive name/key and a description. You can add an unlimited number of properties and manage them.",
  APP_DETAILS_MANIFEST__FIELD_CUSTOM_KEY_PLACEHOLDER: "Type variable name",
  APP_DETAILS_MANIFEST__FIELD_CUSTOM_VALUE_PLACEHOLDER: "Type variable value",
  APP_DETAILS_MANIFEST__FIELD_CUSTOM_DESCRIPTION_LABEL: "Description",
  APP_DETAILS_MANIFEST__FIELD_CUSTOM_DESCRIPTION_TOOLTIP:
    "Description is required. Try to add a complete and understandable description of the value you are adding. This will certainly help other users accessing this space to understand your intentions.",
  APP_DETAILS_MANIFEST__GENERIC_TAB: "Generic",
  APP_DETAILS_MANIFEST__GENERIC_HEADER: "Generic settings",
  APP_DETAILS_MANIFEST__NAVIGATION_TAB: "Navigation",
  APP_DETAILS_MANIFEST__NAVIGATION_HEADER: "Navigation settings",
  APP_DETAILS_MANIFEST__SECURITY_TAB: "Security",
  APP_DETAILS_MANIFEST__SECURITY_HEADER: "Security settings",
  APP_DETAILS_MANIFEST__SECURITY_DESCRIPTION:
    "Security properties are set to TRUE/FALSE. By default, all security properties are set to TRUE. Please use this with caution and only when necessary.",
  APP_DETAILS_MANIFEST__CUSTOM_TAB: "Custom",
  APP_DETAILS_MANIFEST__CUSTOM_HEADER: "Custom settings",
  APP_DETAILS_MANIFEST__SCRIPT_TAB: "User Script",
  APP_DETAILS_MANIFEST__SCRIPT_HEADER: "User Script Settings",
  APP_DETAILS_MANIFEST__SCRIPT_DESCRIPTION:
    "A user script in this context is a JavaScript code snippet that runs from the cloud during app launch. It can override the app code to ensure the functionality works as expected. It should only be used when the app author cannot apply fixes and should be communicated with the app author.",
  APP_DETAILS_MANIFEST__SCRIPT_UPLOAD: "Upload User Script",
  APP_DETAILS_MANIFEST__EDIT_SUBMIT: "Save changes",
  APP_DETAILS_MANIFEST__EDIT_DISCARD: "Discard changes",
  APP_DETAILS_MANIFEST__VALIDATION_COLOR_ERROR:
    "Invalid value (required 6 or 8 hex digits)",
  APP_DETAILS_MANIFEST__VALIDATION_RESOLUTION_ERROR:
    "Invalid value (required {NUM}x{NUM})",

  // App details: moderation
  APP_DETAILS_MODERATION__SECTION_HEADER: "Moderation",
  APP_DETAILS_MODERATION__TAB: "Moderation",
  APP_DETAILS_MODERATION__STATUS: "Moderation status",
  APP_DETAILS_MODERATION__VIEW_HISTORY: "view history",
  APP_DETAILS_MODERATION__START_MODERATION: "Start moderation",
  APP_DETAILS_MODERATION__DONE_MODERATION: "Moderation done",
  APP_DETAILS_MODERATION__APPROVE_METADATA_BUTTON: "Approve submission",
  APP_DETAILS_MODERATION__REJECT_METADATA_BUTTON: "Reject submission",
  APP_DETAILS_MODERATION__FINAL_ACTION_WARNING:
    "Approve Submission - approves the app metadata and makes the App available for final distribution on approved devices (moderation targets). If it's the App update, it will make it live by replacing previous version on approved devices.",
  APP_DETAILS_MODERATION__QA_ROUND: "QA Round",
  APP_DETAILS_MODERATION__APP_ENVIRONMENT: "App environment",
  APP_DETAILS_MODERATION__CHANGE_PRIORITY: "change priority",
  APP_DETAILS_MODERATION__UPDATE_SCOPE: "Update scope",
  APP_DETAILS_MODERATION__MAIN_MODERATOR: "Main moderator",
  APP_DETAILS_MODERATION__CONTENT_MANAGER: "Content manager",
  APP_DETAILS_MODERATION__SUPPORT_LEVEL: "Support level",
  APP_DETAILS_MODERATION__VEWD_APP_STORE_TESTING: "Vewd App Store testing",
  APP_DETAILS_MODERATION__START_TESTING: "Start testing",
  APP_DETAILS_MODERATION__STOP_TESTING: "Stop testing",
  APP_DETAILS_MODERATION__APP_IS_TESTED: "App is being tested",
  APP_DETAILS_MODERATION__APP_IS_NOT_TESTED: "App isn't being tested",
  APP_DETAILS_MODERATION__UPGRADE_TO_PREMIUM: "Upgrade to Premium Support",
  APP_DETAILS_MODERATION__UPGRADE_TO_PREMIUM_LINE1:
    "By upgrading the support level of this application to premium support, the application will be open for support through the Premium Support (CSP) team. The moderation process will start as for a regular TV Store application, but every time special support activities are required, the app can be handed over to the CSP team. Please note that premium support usually implies a significantly higher support cost for an app, so please use the upgrade support function responsibly and not as a means to bump the priority. YOU HAVE BEEN WARNED. All premium support apps have a higher priority than regular application (P1 to P3).",
  APP_DETAILS_MODERATION__UPGRADE_TO_PREMIUM_LINE2:
    "When accepting you must set a priority from P1 to P3.",
  APP_DETAILS_MODERATION__DOWNGRADE_TO_STANDARD:
    "Downgrade to Standard Support",
  APP_DETAILS_MODERATION__DOWNGRADE_TO_STANDARD_LINE1:
    "By downgrading the support level of this application to standard support, the application will follow the regular TV Store moderation flow, without any involvement of the Premium Support (CSP) team. Additionally, priorities P1, P2 and P3 are reserved for Premium Support apps so will have to select a lower (P4 or P5) priority.",
  APP_DETAILS_MODERATION__DOWNGRADE_TO_STANDARD_LINE2:
    "When accepting you must set a priority from P4 to P5.",
  APP_DETAILS_MODERATION__SET_PRIORITY: "Set priority",
  APP_DETAILS_MODERATION__INVALID_STATUS:
    "Given moderation status does not exist",
  APP_DETAILS_MODERATION__SELECT_MODERATOR: "Select moderator",
  APP_DETAILS_MODERATION__SELECT_CONTENT_MANAGER: "Select content manager",
  APP_DETAILS_MODERATION__AFFECTED_DEVICE_MODELS: "affected device models",
  APP_DETAILS_MODERATION__USER_FETCH_ERROR:
    "Error fetching #{roleName} user data",
  APP_DETAILS_MODERATION__EXCLUSIVITY: "Exclusivity",
  APP_DETAILS_MODERATION__UNKNOWN_USER: "Unknown user (ID: #{id})",

  // App details: moderation targets
  APP_DETAILS_MODERATION_TARGETS__ENVIRONMENTS_SECTION_HEADER: "Environments",
  APP_DETAILS_MODERATION_TARGETS__DEVICES_SECTION_HEADER: "Devices",
  APP_DETAILS_MODERATION_TARGETS__TAB: "Moderation targets",
  APP_DETAILS_MODERATION_TARGETS__ACTION_ACCEPT: "Accept",
  APP_DETAILS_MODERATION_TARGETS__ACTION_REJECT: "Reject",
  APP_DETAILS_MODERATION_TARGETS__ACTION_PROCEED: "Proceed application",
  APP_DETAILS_MODERATION_TARGETS__ACTION_ON_HOLD: "On hold application",
  APP_DETAILS_MODERATION_TARGETS__ACTION_PREFORM_BUTTON: "Perform action",
  APP_DETAILS_MODERATION_TARGETS__ACTION_SUCCESS:
    "#{appName} has been #{actionName} on selected devices.",
  APP_DETAILS_MODERATION_TARGETS__REJECT_MODAL_TITLE: "Reject targets",
  APP_DETAILS_MODERATION_TARGETS__REJECT_REASON: "Reject reason",
  APP_DETAILS_MODERATION_TARGETS__REJECTED_INFO:
    "This is rejected version of application",
  APP_DETAILS_MODERATION_TARGETS__ON_HOLD_MODAL_TITLE: "On hold app on targets",
  APP_DETAILS_MODERATION_TARGETS__ON_HOLD_REASON: "On hold reason",
  APP_DETAILS_MODERATION_TARGETS__AFFECTED_DEVICES: "Affected device models",
  APP_DETAILS_MODERATION_TARGETS__APP_REVENUE_NOT_PREMIUM_WARN_A:
    "This App generates direct revenue, and should not be Approved on production devices until App Owner account is updated from Basic to Premium. Contact Vewd Business Development team at",
  APP_DETAILS_MODERATION_TARGETS__APP_REVENUE_NOT_PREMIUM_WARN_B:
    "to request an update.",
  APP_DETAILS_MODERATION_TARGETS__ON_HOLD: "On hold",
  APP_DETAILS_MODERATION_TARGETS__TEST_DEVICE: "Test Device",
  APP_DETAILS_MODERATION_TARGETS__SOME_ON_HOLD_WARNING:
    "The application is put on hold for at least one device",
  APP_DETAILS_MODERATION_TARGETS__REJECTED_WARN:
    "Cannot assign moderation targets to rejected metadata",
  APP_DETAILS_MODERATION_TARGETS__NO_PERMISSIONS:
    "You do not have permissions to view this version of application data.",
  APP_DETAILS_MODERATION_TARGETS__EXCLUSIVE_WARNING:
    "This Application has been marked as “EXCLUSIVE“, make sure you approve it only on the exclusive customers devices.",
  APP_DETAILS_MODERATION_NO_PERMISSIONS_WARNING:
    "You don’t have permission to distribute this app due the lack of agreement provided. Do you still want to proceed?",
  APP_DETAILS_MODERATION_NO_ENVIRONMENT_SELECTED_WARNING:
    "To publish an application at the device, you must enable at least one environment first.",
  APP_DETAILS_MODERATION_NO_ENVIRONMENT_SELECTED: "Unable to publish",
  // App details: messages
  APP_DETAILS_MESSAGES__TAB: "Messages",
  APP_DETAILS_MESSAGES__SECTION_NEW_MESSAGE: "New message",
  APP_DETAILS_MESSAGES__SECTION_ALL_MESSAGES: "All messages",
  APP_DETAILS_MESSAGES__INBOX_PROVISION: "CSP comments",
  APP_DETAILS_MESSAGES__INBOX_MODERATION: "Internal comments",
  APP_DETAILS_MESSAGES__INBOX_DISTRIBUTION: "Distribution",
  APP_DETAILS_MESSAGES__FIELD_COMMENT: "Comment",
  APP_DETAILS_MESSAGES__FIELD_INBOX_POST_TO: "Post to",
  APP_DETAILS_MESSAGES__SUBMIT_BUTTON: "Post comment",
  APP_DETAILS_MESSAGES__COLUMN_AUTHOR: "Author",
  APP_DETAILS_MESSAGES__COLUMN_TYPE: "Type",
  APP_DETAILS_MESSAGES__COLUMN_DATE: "Date",
  APP_DETAILS_MESSAGES__FILTERS_SECTION_NAME: "Messages filters",
  APP_DETAILS_MESSAGES__SEARCH_PLAEHOLDER: "Search & filter messages",
  APP_DETAILS_MESSAGES__RELATED_TASKS: "Related tasks",
  APP_DETAILS_MESSAGES__RELATED_DEVICES: "Related devices",
  APP_DETAILS_MESSAGES__COMMENT_TOOLTIP: `Heading
  =======

  ## Sub-heading

  Paragraphs are separated by a blank line.

  Two spaces at the end of a line produces a line break.

  Text attributes:
  _italic_, **bold**, \`code\`

  Blockquotes:
  > This is a blockquote

  Horizontal rule:
  ---

  Bullet list:
  * apples
  * oranges
  * pears

  Numbered list:
  1. wash
  2. rinse
  3. repeat

  A [link](example).
  [example]: http://example.com
  `,

  // Appt details: Rights & Contract
  APP_DETAILS_RIGHTS__TAB: "Rights & Contract",
  APP_DETAILS_RIGHTS__HEADER: "Rights & Contract",
  APP_DETAILS_RIGHTS__CD_TYPE_HEADER: "Certification & Distribution type",
  APP_DETAILS_RIGHTS__CD_TYPE_CATALOG: "Vewd Catalog",
  APP_DETAILS_RIGHTS__CD_TYPE_CATALOG_DIST: "Vewd Catalog (Distribution)",
  APP_DETAILS_RIGHTS__CD_TYPE_CERTIFICATION_PROGRAM:
    "Vewd Certification Program",
  APP_DETAILS_RIGHTS__CD_TYPE_EXAMINE: "Examine",
  APP_DETAILS_RIGHTS__CMA_HEADER: "Content manager approval",
  APP_DETAILS_RIGHTS__CMA_STATUS_WAITING: "Waiting for approval",
  APP_DETAILS_RIGHTS__CMA_STATUS_ACCEPTED: "Accepted",
  APP_DETAILS_RIGHTS__CMA_STATUS_REJECTED: "Rejected",
  APP_DETAILS_RIGHTS__CMA_STATUS_TOOLTIP:
    "Approve or reject the application. Approval will unlock new management options.",
  APP_DETAILS_RIGHTS__AGREEMENTS_HEADER: "Salesforce",
  APP_DETAILS_RIGHTS__AGREEMENTS_DESCRIPTION:
    "Provide Salesforce ID to generate URL to the Salesforce contract separately for each distributor organization.",
  APP_DETAILS_RIGHTS__AGREEMENTS_ADD: "Add agreement",
  APP_DETAILS_RIGHTS__AGREEMENTS_DELETE: "Delete Salesforce agreement",
  APP_DETAILS_RIGHTS__AGREEMENTS_NO_AGREEMENTS: "No Salesforce ID added",
  APP_DETAILS_RIGHTS__AGREEMENTS_TABLE_ORGANIZATION: "Organization",
  APP_DETAILS_RIGHTS__AGREEMENTS_TABLE_SALESFORCE_ID: "Salesforce ID",
  APP_DETAILS_RIGHTS__AGREEMENTS_MODAL_LABEL_SALESFORCE_ID: "Salesforce ID",
  APP_DETAILS_RIGHTS__AGREEMENTS_MODAL_LABEL_SALESFORCE_URL: "Salesforce URL",
  APP_DETAILS_RIGHTS__AGREEMENTS_MODAL_LABEL_ORGANIZATION:
    "Select organization",
  APP_DETAILS_RIGHTS__AGREEMENTS_MODAL_DELETE_WARNING_DESCRIPTION:
    "This application is already published. To delete Saleforce ID, please change application status to unpublished first.",

  // App details: certification
  APP_DETAILS_CERTIFICATION__TAB: "Certification",
  APP_DETAILS_CERTIFICATION__SECTION_HEADER: "Certification",
  APP_DETAILS_CERTIFICATION__UNAVAILABLE_DUE_TO_APP_TYPE:
    "Certification is unavailable due to application type.",
  APP_DETAILS_CERTIFICATION__UNAVAILABLE_DUE_TO_CERT_TYPE:
    "Certification is unavailable due to Certification & Distribution type.",
  APP_DETAILS_CERTIFICATION__WAITING_FOR_APPROVAL_A: "Content Manager’s",
  APP_DETAILS_CERTIFICATION__WAITING_FOR_APPROVAL_B: "approval decision",
  APP_DETAILS_CERTIFICATION__WAITING_FOR_APPROVAL_C:
    "has to be made first to enable this section.",
  APP_DETAILS_CERTIFICATION__SEND_EMAIL: "Send Certification email",
  APP_DETAILS_CERTIFICATION__STATUS_CHANGE_ERROR: "Failed setting status",
  APP_DETAILS_CERTIFICATION__PRIORITY: "Priority",
  APP_DETAILS_CERTIFICATION__ACP_APPROVE_CERTIFICATION_MODAL_TITLE:
    "Accept Non-Catalog app for Certification only",
  APP_DETAILS_CERTIFICATION__ACP_APPROVE__CERTIFICATION_MODAL_CONTENT:
    "You are about to accept the application for Certification only. Confirmation email will be send to developer's organization email address, and a copy to your registered address. Please set a priority for the process and confirm acceptance.",
  APP_DETAILS_CERTIFICATION__ACP_REJECT_CERTIFICATION_MODAL_TITLE:
    "Reject app for Certification only",
  APP_DETAILS_CERTIFICATION__ACP_REJECT_CERTIFICATION_MODAL_CONTENT:
    "You are about to reject the application for Certification only. Confirmation email will be send to developer's organization email address, and a copy to your registered address. Please confirm rejection.",
  APP_DETAILS_CERTIFICATION__ACP_APPROVE_EXAMINATION_MODAL_TITLE:
    "Accept Non-Catalog app for Examination only",
  APP_DETAILS_CERTIFICATION__ACP_APPROVE_EXAMINATION_MODAL_CONTENT:
    "You are about to accept the Non-Catalog application. Confirmation email will be send to developer’s organization email address, and a copy to your registered address. Please set a priority for the process and confirm acceptance.",
  APP_DETAILS_CERTIFICATION__ACP_REJECT_EXAMINATION_MODAL_TITLE:
    "Reject app for Examination only",
  APP_DETAILS_CERTIFICATION__ACP_REJECT_EXAMINATION_MODAL_CONTENT:
    "You are about to reject the application for Examination only. Confirmation email will be send to developer's organization email address, and a copy to your registered address. Please confirm rejection.",
  APP_DETAILS_CERTIFICATION__ACCEPTED: "Accepted",
  APP_DETAILS_CERTIFICATION__PENDING: "Pending",
  APP_DETAILS_CERTIFICATION__REJECTED: "Rejected",

  // App details: App Rank
  APP_DETAILS_APP_RANK__TAB: "Rank",
  APP_DETAILS_APP_RANK__TOP_MARKETS: "MARK AS TOP APPLICATION IN:",
  APP_DETAILS_APP_RANK__SECTION_HEADER: "MARK APPLICATION AS A TOP PICK:",
  APP_DETAILS_APP_RANK__SECTION_DEFINITION:
    "Applications marked as TOP will be displayed on the lists in a special way. You can search for these types of applications using:",
  APP_DETAILS_APP_RANK__FILTER: "Filter",
  APP_DETAILS_APP_RANK__FILTER_KEY: "top_apps:",
  APP_DETAILS_APP_RANK__FILTER_VALUE: "country",
  APP_DETAILS_APP_RANK__FILTER_CATEGORIES:
    "Applications can be marked as TOP in two categories:",
  APP_DETAILS_APP_RANK__FILTER_TOP_COUNTRIES: "„TOP IN COUNTRIES”",
  APP_DETAILS_APP_RANK__FILTER_TOP_GLOBAL: "„TOP GLOBAL”",
  APP_DETAILS_APP_RANK__FILTER_OR: "OR",
  APP_DETAILS_APP_RANK__RANK_STATUS: "RANK STATUS",
  APP_DETAILS_APP_RANK__STATUS: "Status",
  APP_DETAILS_APP_RANK__NONE_STATUS: "None",
  APP_DETAILS_APP_RANK__MARKED_STATUS: "Marked as TOP",
  APP_DETAILS_APP_RANK__TOP: "TOP",
  APP_DETAILS_APP_RANK__PICKED_GLOBAL: "Application picked as TOP GLOBAL",
  APP_DETAILS_APP_RANK__PICKED_LOCAL: "Application picked as TOP LOCAL",

  // App details: partial result
  APP_DETAILS_PARTIAL_RESULT__TITLE: "Partial",
  APP_DETAILS_PARTIAL_RESULT__DESCRIPTION:
    "This is a partial result. Open the application to see all the details.",

  // App details: name change feedback
  APP__NAME_CHANGE_SUCCESS:
    "App name change request has been successfully submitted.",
  APP__NAME_CHANGE_ERROR:
    "There was some unexpected error. App name change request failed.",

  // App Manifests

  APP_MANIFESTS_ROUTE_NAME: "App manifests",
  APP_MANIFESTS_EDIT_PAGE_HEADER: "Application manifest",
  APP_MANIFESTS_CREATE_ROUTE_NAME: "Create new manifest",
  APP_MANIFESTS_EDIT_ROUTE_NAME: "Edit manifest",
  APP_MANIFESTS_NO_RESULTS: "No apps with manifests found",
  APP_MANIFESTS_EDIT_MANIFEST: "Edit Manifest",
  APP_MANIFESTS_CREATE_MANIFEST: "Create new Manifest",
  APP_MANIFESTS_SEARCH_PLACEHOLDER:
    "Search for application manifest by application id",
  APP_MANIFESTS_APPLICATION_GUIDE_TITLE: "Manifest",
  APP_MANIFESTS_APPLICATION_GUIDE: `
    <p>The input field supports two modes of operations to enhance experience in selecting apps: </p>
    <p>
    <span>Searchable list:</span>  For ease of use, this mode allows to search and select
      from list of applications available in our app inventory. Upon beginning to type, relevant apps will appear for selection.
    </p>
    <p>
      <span>Manual ID entry:</span> This mode is ideal for configuring external app ID's
    </p>
    <p>
    <span>Important note:</span>
      To ensure each application's configuration remains unique and to prevent duplication, any
      application that has been previously selected and configured will not appear in the searchable list.
    </p>`,
  APP_MANIFESTS_FORM_VALIDATION_ERROR:
    "Form validation error:\r\n #{text}. \r\n Make sure to correct that and try again!.",
  APP_MANIFESTS_TARGET_TAB_SWITCH_LABEL: "Use internal application Id",
  APP_MANIFESTS_TARGET_TAB_APP_ID_LABEL: "Application id",
  APP_MANIFESTS_TARGET_TAB_HEADER_PRIMARY: "Application Name",
  APP_MANIFESTS_TARGET_TAB_HEADER_SECONDARY: "Application Id",
  APP_MANIFESTS_TARGET_TAB_APP_ID_ERROR:
    "This app already has a manifest created. Please pick another one",

  // Device Pairing
  DEVICE_PAIRING__PAGE_HEADER: "Device Pairing",
  DEVICE_PAIRING__SECTION_HEADER: "Device Pairing",
  DEVICE_PAIRING__ROUTE_NAME: "Device Pairing",
  DEVICE_PAIRING__ID_FOR_DEVELOPERS_TEXT: `By setting up paired devices, you can test your applications
  in the Vewd App Store even before they've submitted and
  reviewed. To pair a device, you will need device`,
  DEVICE_PAIRING__ID_FOR_DEVELOPERS_CODE: "ID FOR DEVELOPERS",
  DEVICE_PAIRING__ID_FOR_DEVELOPERS_64_CHAR: "or 64-character",
  DEVICE_PAIRING__ID_FOR_DEVELOPERS_DEVICE_ID: "DEVICE ID",
  DEVICE_PAIRING__DEVELOPMENT_CATEGORY: `After a device has been paired, you will see a new Develop
  category under the Vewd App Store tab. This category contains
  all your saved, submitted and reviewed applications. This
  allow you to test exactly how an application that is still in
  development will look and behave in the store.`,
  DEVICE_PAIRING__REQUIRES_CONFIRMATION:
    "Pairing using ID FOR DEVELOPERS (requires confirmation)",
  DEVICE_PAIRING__ID_PLACEMENT_IN_STORE: `ID FOR DEVELOPERS can be found by opening the Vewd App Store
  on the device and open additional menu (the MENU button in the
  top-right corner) under the My Apps tab. Press ID FOR
  DEVELOPERS button to generate unique ID for pairing. Enter
  this ID in the form below to associate the device with your
  submission portal account.`,
  DEVICE_PAIRING__PROMPT_INFO: `After that you should be prompt on TV to accept pairing. Once
  you accept it on TV your account and device will be finally
  associated. You will see your pairing on the list below with
  full 64-character DEVICE ID.`,
  DEVICE_PAIRING__DONT_LEAVE_ABOUT_PAGE: `Please note that you should not leave About page while
  pairing. Also note that ID FOR DEVELOPERS will be valid only
  for 15 minutes. If you fail to enter ID in form below in that
  time you will have to generate it again by pressing ID FOR
  DEVELOPERS button.`,
  DEVICE_PAIRING__NO_CONFIRMATION_REQUIRED:
    "Pairing using DEVICE ID (no confirmation required)",
  DEVICE_PAIRING__64_CHAR_PLACEMENT: `DEVICE ID can be found by opening the Vewd App Store on the
  device and open additional menu (the MENU button in the
  top-right corner) under the My Apps tab. Press INFORMATION
  button to open information window containing DEVICE ID. Enter
  this ID in the form below to associate the device with your
  submission portal account.`,
  DEVICE_PAIRING__PAIR_NEW_DEVICE: "Pair new device",
  DEVICE_PAIRING__PAIRED_DEVICES: "Paired devices",
  DEVICE_PAIRING__PAIRED: "Paired",
  DEVICE_PAIRING__PAIRING_WAITING: "Waiting for confirmation...",
  DEVICE_PAIRING__REJECTED: "Rejected",
  DEVICE_PAIRING__ERROR: "Error",
  DEVICE_PAIRING__UNPAIR: "Unpair",
  DEVICE_PAIRING__CUSTOM_NAME: "Custom name",
  DEVICE_PAIRING__BUTTON_PAIRING: "Pairing",
  DEVICE_PAIRING__BUTTON_PAIR: "Pair",
  DEVICE_PAIRING__SUCCESS: "Success! You have just paired with a device!",
  DEVICE_PAIRING__CONFIRM: "Success! Please confirm pairing on your device!",

  // Distribution plans
  DISTR_DEFAULT_PLAN__PLAN_ROUTE_NAME: "Default plan",
  DISTR_CUSTOM_PLANS__ROUTE_NAME: "Custom plans",
  DISTR_PLAN__ADMIN_ROUTE_NAME: "Distribution admin",
  DISTR_PLAN__ADD_ROUTE_NAME: "Add distribution plan",
  DISTR_PLAN__EDIT_ROUTE_NAME: "Edit distribution plan",
  DISTR_PLAN__TARGET_NOT_FOUND_ERROR:
    "Target #{targetId} of plan #{planId} is not found.",
  DISTR_DEFAULT_PLAN__SECTION_APPS_TO_DISTRIBUTE_HELP:
    'In the Default Distribution Plan all Apps, approved by your organization will be published automatically. You can use Auto Approve for the new available Apps, or moderate them manually in the "Applications / Apps to distribute" section.',

  // Distribution plans: default plan
  DISTR_DEFAULT_PLAN__PAGE_HEADER: "Default Distribution Plan",
  DISTR_DEFAULT_PLAN__AUTOAPPROVE_LABEL: "Automatically approve new apps after",
  DISTR_DEFAULT_PLAN__AUTOAPPROVE_NONE: "0 weeks",
  DISTR_DEFAULT_PLAN__AUTOAPPROVE_ONE_WEEK: "1 week",
  DISTR_DEFAULT_PLAN__AUTOAPPROVE_TWO_WEEKS: "2 weeks",

  // Distribution plans: edit plan
  DISTR_PLAN__EDIT_PAGE_HEADER: "Edit Distribution Plan",
  DISTR_PLAN__CREATE_PAGE_HEADER: "New Distribution Plan",
  DISTR_PLAN__CREATE_SUCCESS: "The distribution plan has been added",
  DISTR_PLAN__SECTION_BASIC_DETAILS: "Basic details",
  DISTR_PLAN__FIELD_NAME: "Plan name",
  DISTR_PLAN__FIELD_DISTRIBUTOR: "Distributor",
  DISTR_PLAN__FIELD_DISTRIBUTOR_PLACEHOLDER: "Choose distributor",
  DISTR_PLAN__FIELD_DESCRIPTION: "Description",
  DISTR_PLAN__FIELD_APP_REJECTED: "(App is not available anymore)",
  DISTR_PLAN__FIELD_APP_ON_HOLD_A: "(App is",
  DISTR_PLAN__FIELD_APP_ON_HOLD_B: "On Hold",
  DISTR_PLAN__FIELD_APP_ON_HOLD_C: "on some of your devices)",
  DISTR_PLAN__SECTION_RESTRICTED_DISTRIBUTORS: "Restricted distributors",
  DISTR_PLAN__SECTION_RESTRICTED_DISTRIBUTORS_HELP:
    "Allow following users to publish app in this plan",
  DISTR_PLAN__SECTION_RESTRICTED_DISTRIBUTORS_ONE: "distributor selected",
  DISTR_PLAN__SECTION_RESTRICTED_DISTRIBUTORS_MANY: "distributors selected",
  DISTR_PLAN__SECTION_TARGETS: "Targets",
  DISTR_PLAN__SECTION_APPS_TO_DISTRIBUTE: "Apps to distribute",
  DISTR_PLAN__SECTION_APPS_TO_DISTRIBUTE_HELP:
    "Choose apps to be published on selected targets",
  DISTR_PLAN__APPS_SELECTED_ONE: "app selected",
  DISTR_PLAN__APPS_SELECTED_MANY: "apps selected",

  // Distribution plans: custom plans list
  DISTR_CUSTOM_PLANS__PAGE_HEADER: "Custom Distribution Plans",
  DISTR_CUSTOM_PLANS__CREATE_BUTTON: "Create custom plan",
  DISTR_CUSTOM_PLANS__REMOVE_SUCCESS: "The plan has been removed",
  DISTR_CUSTOM_PLANS__REMOVE_MODAL_TITLE: "Remove custom plan",
  DISTR_CUSTOM_PLANS__REMOVE_MODAL_TEXT:
    'Are you sure you want to delete "#{name}" plan?',

  // Distribution plans: admin plans list
  DISTR_ADMIN_PLANS__PAGE_HEADER: "Administrate Distribution Plans",
  DISTR_ADMIN_PLANS__CREATE_BUTTON: "Create plan",
  DISTR_PLAN__ADMIN_EDIT_DEFAULT_PLAN_BUTTON: "Edit default plan",

  // Targets
  TARGETS__ALL_DEVICES_FROM_ORGANIZATION:
    "All devices from #{orgName} organization",
  TARGETS__EDIT_DEVICES: "Edit Devices",
  TARGETS__EDIT_REGIONS: "Edit Regions",
  TARGETS__EDIT_QUERY: "Edit Query",
  TARGETS__REMOVE: "Remove target",
  TARGETS__ADD: "Add new target",
  TARGETS__DEVICE: "Device",
  TARGETS__NO_SELECTED: "No targets selected",
  TARGETS__NO_DEVICE_BUTTON_TEXT: "Select Devices",
  TARGETS__NO_REGION_BUTTON_TEXT: "Select Regions",
  TARGETS__REGIONS_MODAL_TITLE: "Edit target regions",
  TARGETS__REGIONS_MODAL_TITLE_READONLY: "Target regions",
  TARGETS__REGIONS_MODAL_CONTENT: "Select regions from list below",
  TARGETS__REGIONS_MODAL_WORLDWIDE: "Worldwide",
  TARGETS__REGIONS_MODAL_SPECIFIC_COUNTRIES: "Specific Countries",
  TARGETS__DEVICES_MODAL_MY: "my",
  TARGETS__DEVICES_MODAL_TITLE: "Edit target devices",
  TARGETS__DEVICES_MODAL_CONTENT: "Select devices from the list below",
  TARGETS__DEVICES_MODAL_MODE_DEVICES: "Specific Devices",
  TARGETS__DEVICES_MODAL_MODE_FAMILIES: "Specific Families",
  TARGETS__FETCH_DEVICE_ERROR: "Device model with id #{id} is not found.",
  TARGETS__FETCH_FAMILY_ERROR: "Specific Families",
  TARGETS__FETCH_ORGANIZATION_ERROR: "Specific Families",
  TARGETS__SEND_ERROR: "Sorry, error during processing your response",
  TARGETS_ALL_DEVICES_FROM_ORG_WITHOUT_NAME: "All devices from organizations",
  TARGETS_ALL_DEVICES: "All devices",
  TARGETS_ALL_DEVICES_DESCRIPTION:
    "All registered device models from all distributors organizations. Every new distributor organization will be also included in this target.",
  TARGETS_QUERY_AND_FILTER: "Query",
  TARGETS_QUERY_AND_FILTER_NOTICE:
    "Only device units registered in the #{deviceInventoryLink} can be targeted by Query.",
  TARGETS_QUERY_AND_FILTER_DESCRIPTION:
    "Query is dynamic and its results might change every time you run it, because devices can be added/removed in the Inventory, or their Attributes values can change.",
  TARGETS_DEVICE_MODELS: "Device models",
  TARGETS_DEVICE_FAMILIES: "Device families",
  TARGETS_DEVICE_UNITS_BY_QUERY: "Device units by query",
  TARGETS_DEVICE_PDID: "Device PDID",
  TARGETS_DEVICE_APPROX_NUMBER: "Approx. number of device units: #{number}",

  // Software assets
  ASSET__BASIC_INFO_HEADER: "Basic information",
  ASSET__TITLE_LABEL: "Asset title",
  ASSET__TITLE_TOOLTIP: "Asset name",
  ASSET__ID_LABEL: "Asset id",
  ASSET__ID_TOOLTIP: "Asset unique id",
  ASSET__DESCRIPTION_LABEL: "Description",
  ASSET__DESCRIPTION_TOOLTIP: "Asset description",
  ASSET__ICON_LABEL: "Asset icon",
  ASSET__ICON_TOOLTIP: "Asset icon. Format png required",
  ASSET__VERSION_LABEL: "Version number",
  ASSET__VERSION_TOOLTIP: "Asset version number",
  ASSET__VERSION_URL_LABEL: "Version url",
  ASSET__VERSION_URL_TOOLTIP: "Asset url",
  ASSET__VERSIONS_HEADER: "Versions",
  ASSET__SHOULD_BE_UNIQUE_ERR_MSG:
    "Version with the same number already exists in this Asset. Version number should be unique.",
  ASSET__URL_SHOULD_BE_UNIQUE_ERR_MSG:
    "Version with the same url already exists in this Asset. Url should be unique.",
  ASSET__ADD_VERSION_BUTTON: "+ Add version",
  ASSET__SET_VERSION_AS_DEFAULT_BUTTON: "Set version as default",
  ASSET__DUPLICATE_VERSION_BUTTON: "Duplicate version",
  ASSET__PUBLISH_TO_DEVICE_TITLE: "Publish to device",
  ASSET__PUBLISH_TO_REGION_TITLE: "Publish to region",
  ASSET__SELECT_DEVICE_FOR_HEADER: "Select device for",
  ASSET__SELECT_REGIONS_FOR_HEADER: "Select regions for",
  ASSET__VERSION_CONFLICT_ERR_MSG:
    "Version #{version} is already published in #{countryCnt}",
  ASSET__ASSETS_PAGE_HEADER: "Assets",
  ASSET__SOFTWARE_ASSETS_PAGE_HEADER: "Software Asset",
  ASSET__SOFTWARE_ASSETS_HEADER: "Software assets",
  ASSET__SEARCH_ASSET_PLACEHOLDER: "Search for asset name",
  ASSET__ADD_NEW_ASSET_BUTTON: "Add new asset",
  ASSET__WORLDWIDE_ALL: "Worldwide (all)",
  ASSET__DEVICES_HEADER: "Devices",
  ASSET__NAME_COLUMN: "Name",
  ASSET__REGIONS_COLUMN: "Regions",
  ASSET__NO_DEVICES_SELECTED: "No devices selected",
  ASSET__ADD_DEVICES_BUTTON: "Add devices",
  ASSET__EDIT_DEVICES_BUTTON: "Edit devices",
  ASSET__EDIT_REGIONS_BUTTON: "Edit regions",
  ASSET__REMOVE_DEVICE_BUTTON: "Remove device",
  ASSET__LIST_ROUTE_NAME: "Software Assets",
  ASSET__CREATE_ROUTE_NAME: "New Software Asset",
  ASSET__EDIT_ROUTE_NAME: "Edit Software Asset",
  ASSET__ASSETS_ROUTE_NAME: "Software assets",

  // Promotions
  PROMOTIONS__ROUTE_NAME: "Promotions",
  PROMOTION_LIST__ROUTE_NAME: "Promotion",
  PROMOTION_ADMIN_LIST__ROUTE_NAME: "Predefined Promotion",
  PROMOTION_CREATE__ROUTE_NAME: "Create new promotion",
  PROMOTION_EDIT__ROUTE_NAME: "Edit promotion",
  PROMOTION_TYPE_LIST__ROUTE_NAME: "Promotion types",
  PROMOTION_TYPE_CREATE__ROUTE_NAME: "Add promotion type",
  PROMOTION_TYPE_EDIT__ROUTE_NAME: "Edit promotion type",

  // Promotions list
  PROMOTION_LIST__PAGE_HEADER: "Promotions",
  PROMOTION_LIST__SECTION_NAME: "Promotions for #{organization}",
  PROMOTION_LIST__SECTION_NAME_ADMIN: "Predefined Promotions",
  PROMOTION_LIST__COLUMN_APPS: "App/s",
  PROMOTION_LIST__COLUMN_CAMPAIGNS: "Campaigns",
  PROMOTION_LIST__SEARCH_PLACEHOLDER: "Search for promotion name",
  PROMOTION_LIST__CREATE_BUTTON: "Create Promotion",
  PROMOTION_LIST__REMOVE_PROMOTION: "Remove Promotion",
  PROMOTION_LIST__REMOVE_PROMOTION_MODAL_TITLE: "Warning",
  PROMOTION_LIST__REMOVE_PROMOTION_MODAL_TEXT:
    'Do you want to remove "#{promotionName}" promotion?',
  PROMOTION_LIST__FILTER_SECTION_NAME: "Default section",
  PROMOTION_LIST__FILTER_MANAGER_DESCRIPTION: "Filter list by the creator role",

  // Promotions edit/create
  PROMOTION_EDIT__PAGE_HEADER: "Edit promotion",
  PROMOTION_CREATE__PAGE_HEADER: "Create new promotion",
  PROMOTION_EDIT__SECTION_BASIC_DETAILS: "Basic details",
  PROMOTION_CREATE__SUBMIT_BUTTON: "Create new promotion",
  PROMOTION_EDIT__FIELD_NAME: "Promotion name",
  PROMOTION_EDIT__FIELD_TYPE: "Promotion type",
  PROMOTION_EDIT__FIELD_TYPE_PLACEHOLDER: "Choose type",
  PROMOTION_EDIT__ONGOING_CAMPAIGN_WARNING_A:
    "You are about to edit promotion that is in the next ongoing/scheduled campaigns:",
  PROMOTION_EDIT__ONGOING_CAMPAIGN_WARNING_B:
    "Are you sure that you would like to confirm changes?",
  PROMOTION_DETAILS__SUBMIT_SUCCESS: "The promotion has been updated",
  PROMOTION_DETAILS__TAB_PREFIX: "App",
  PROMOTION_DETAILS__INVALID_DATA_ERROR: "Promotion data is not valid",
  PROMOTION_DETAILS__SECTION_DETAILS: "Apps details",
  PROMOTION_DETAILS__FIELD_APP: "Application",
  PROMOTION_DETAILS__FIELD_APP_PLACEHOLDER: "Pick an app...",
  PROMOTION_DETAILS__FIELD_FULL_PAGE_LANDSCAPE: "Full page landscape",
  PROMOTION_DETAILS__FIELD_FULL_PAGE_LANDSCAPE_TOOLTIP:
    "Full page landscape should be an attractive, graphical representation of the your application.",
  PROMOTION_DETAILS__FIELD_FULL_PAGE_LANDSCAPE_INFO:
    "#{format}, RESOLUTION MIN #{minWidth}x#{minHeight} PX MAX #{maxWidth}x#{maxHeight} PX (Recommended), ASPECT RATIO #{aspectRatio}",
  PROMOTION_DETAILS__FIELD_DESCRIPTION: "Description",
  PROMOTION_DETAILS__FIELD_ICON: "Custom icon",
  PROMOTION_DETAILS__FIELD_ICON_INFO:
    "#{format}, RESOLUTION #{minWidth}X#{minHeight} PX OR GREATER, ASPECT RATIO #{aspectRatio}",
  PROMOTION_DETAILS__FIELD_THUMBNAIL: "Custom thumbnail",
  PROMOTION_DETAILS__FIELD_THUMBNAIL_INFO:
    "#{format}, RESOLUTION #{minWidth}X#{minHeight} PX OR GREATER, ASPECT RATIO #{aspectRatio}",
  PROMOTION_DETAILS__FIELD_TITLE: "Title",
  PROMOTION_DETAILS__CONTENT_TITLE: "Promotion content items",
  PROMOTION_DETAILS__CONTENT_ADD_ITEM: "Add promotion content item",
  PROMOTION_DETAILS__CONTENT_VIDEO_URL: "Video Url",
  PROMOTION_DETAILS__CONTENT_FULL_PAGE_LANDSCAPE: "Full-page landscape",
  PROMOTION_DETAILS__CONTENT_DEFINE: "Define promotion content item",
  PROMOTION_DETAILS__CONTENT_SELECT: "Select content item mandatory fields",
  PROMOTION_DETAILS__CONTENT_ITEMS_ELEMENT: "Promotion content items elements",
  PROMOTION_DETAILS__CONTENT_OPTIONAL_ELEMENTS: "Optional elements",
  PROMOTION_DETAILS__CONTENT_ITEM_TITLE: "Title",
  PROMOTION_DETAILS__CONTENT_ITEM_CONTENT: "Content (video/full-page landscape",
  PROMOTION_DETAILS__CONTENT_ITEM_DESCRIPTION: "Description",
  PROMOTION_DETAILS__CONTENT_NO_ITEMS: "No promotion content item added",
  PROMOTION_DETAILS__CONTENT_ITEM_CUSTOM_DESCRIPTION: "Custom description",
  PROMOTION_DETAILS__CONTENT_ITEM_CUSTOM_TITLE: "Custom title",
  PROMOTION_DETAILS__CONTENT_ITEM_DEEPLINK: "Deeplink Uri",
  PROMOTION_DETAILS__CONTENT_ITEM_DEEPLINK_ERROR: "Enter valid Deeplink URI",
  PROMOTION_DETAILS__CONTENT_ITEM_DEEPLINK_ERROR_GENERIC:
    "Please review all content items and verify if valid Deeplink URIs were entered.",
  PROMOTION_DETAILS__CONTENT_ITEM_DEEPLINK_INFO:
    "A content deep-link is an URL within the application which either plays out the content or explains more information about the content before it can be played.",
  PROMOTION_DETAILS__CONTENT_ITEM_THUMBNAIL: "Video URL thumbnail",
  PROMOTION_DETAILS__CONTENT_ITEM_THUMBNAIL_INFO:
    "JPEG or PNG, max image size #{maxSize} MB, aspect ratio #{aspectRatio}",
  PROMOTION_DETAILS__FIELD_ACTION: "Action label",
  PROMOTION_DETAILS__WATCH_ACTION_LABEL: "WATCH",
  PROMOTION_DETAILS__LISTEN_ACTION_LABEL: "LISTEN",
  PROMOTION_DETAILS__OPEN_ACTION_LABEL: "OPEN",
  PROMOTION_DETAILS__MORE_ACTION_LABEL: "MORE",
  PROMOTION_DETAILS__CUSTOM_ACTION_PLACEHOLDER: "Custom string",
  PROMOTION_DETAILS__FIELD_JSON: "Define JSON file",
  PROMOTION_DETAILS__FIELD_JSON_UPLOAD: "Upload JSON",
  PROMOTION_DETAILS__FIELD_JSON_ERROR: "Invalid JSON. Data cannot be displayed",
  PROMOTION_DETAILS__NON_APP_SECTION_DETAILS: "Define promotion details",
  PROMOTION_DETAILS__PROMOTION_TYPE_FETCHING_ERROR:
    "Error fetching promotion type data",
  PROMOTION_DETAILS_WARNING_MESSAGE:
    "Please ensure that the asset has been verified and approved by the relevant application owner. In case of any doubt, please contact your Key Account Manager from Vewd",
  PROMOTION_DETAILS_APP_NOT_AVAILABLE:
    "This application is not available for your device",

  // Promotion types list
  PROMOTION_TYPE_LIST__PAGE_HEADER: "Promotion Types",
  PROMOTION_TYPE_LIST__SECTION_NAME: "Promotion Types",
  PROMOTION_TYPE_LIST__COLUMN_ASSETS: "Assets",
  PROMOTION_TYPE_LIST__COLUMN_PROMOTION_COUNT_A: "Number of",
  PROMOTION_TYPE_LIST__COLUMN_PROMOTION_COUNT_B: "promotions",
  PROMOTION_TYPE_LIST__COLUMN_PROMOTION_COUNT_C: "created",
  PROMOTION_TYPE_LIST__CREATE_BUTTON: "Add new promotion type",
  PROMOTION_TYPE_LIST__REMOVE_TYPE: "Remove type",
  PROMOTION_TYPE_LIST__REMOVE_TYPE_MODAL_TITLE: "Remove promotion type",
  PROMOTION_TYPE_LIST__REMOVE_TYPE_MODAL_TEXT:
    'Are you sure you want to delete "#{promotionTypeName}"?',

  // Promotion types edit/create
  PROMOTION_TYPE_EDIT__PAGE_HEADER: "Edit promotion type",
  PROMOTION_TYPE_CREATE__PAGE_HEADER: "Add new promotion type",
  PROMOTION_TYPE_CREATE__SUCCESS: "The promotion type has been added",
  PROMOTION_TYPE_EDIT__SECTION_BASIC_DETAILS: "Basic details",
  PROMOTION_TYPE_EDIT__SECTION_METADATA: "Additional meta data",
  PROMOTION_TYPE_CREATE__SUBMIT_BUTTON: "Add new promotion type",
  PROMOTION_TYPE_EDIT__FIELD_APPS_COUNT: "Number of apps in this promotion",
  PROMOTION_TYPE_EDIT__FIELD_APPS_COUNT_PLACEHOLDER: "Choose apps count",
  PROMOTION_TYPE_EDIT__FIELD_APPS_COUNT_REQUIRED_WARNING: "Field is required.",
  PROMOTION_TYPE_EDIT__FIELD_AUTO_SLUG: "autogenerated",
  PROMOTION_TYPE_EDIT__FIELD_FULL_PAGE_LANDSCAPE:
    "Additional full page landscape",
  PROMOTION_TYPE_EDIT__FIELD_JSON: "Additional JSON file",
  PROMOTION_TYPE_EDIT__FIELD_ACTION: "Action Label",
  PROMOTION_TYPE_EDIT__FIELD_DESCRIPTION: "Custom description",
  PROMOTION_TYPE_EDIT__FIELD_ICON: "Custom icon",
  PROMOTION_TYPE_EDIT__FIELD_NAME: "Promotion type name",
  PROMOTION_TYPE_EDIT__FIELD_SLUG: "Slug",
  PROMOTION_TYPE_EDIT__FIELD_SLUG_B: "Promotion type slug",
  PROMOTION_TYPE_EDIT__FIELD_SOFTWARE_ASSETS: "Software assets",
  PROMOTION_TYPE_EDIT__FIELD_THUMBNAIL: "Custom thumbnail",
  PROMOTION_TYPE_EDIT__FIELD_TITLE: "Custom title",
  PROMOTION_TYPE_EDIT__FIELD_CONTENT_ITEMS: "Content items",
  PROMOTION_TYPE_EDIT__APPS_COUNT_ZERO_TEXT:
    "Promotion without an application (0)",
  PROMOTION_TYPE_EDIT__ERROR_AT_LEAST_ONE_ADDITIONAL_METADATA:
    "At least one additional metadata should be selected",
  PROMOTION_TYPE_EDIT__ORGANIZATIONS_SECTION_HEADER:
    "Promotion type availability",
  PROMOTION_TYPE_EDIT__ORGANIZATIONS_TABLE_HEADER: "Organization",
  PROMOTION_TYPE_EDIT__ORGANIZATIONS_MANAGE: "Manage organizations",
  PROMOTION_TYPE_EDIT__ORGANIZATIONS_NO_ITEMS: "No organization selected",
  PROMOTION_TYPE_EDIT__ORGANIZATIONS_MODAL_TITLE: "Select organization",
  PROMOTION_TYPE_EDIT__ORGANIZATIONS_MODAL_STATUS: "elements selected",

  // Campaigns
  CAMPAIGN_LIST__ROUTE_NAME: "Campaigns",
  CAMPAIGN_ADMIN_LIST__ROUTE_NAME: "Predefined Campaigns",
  CAMPAIGN_CREATE__ROUTE_NAME: "Campaign Create",
  CAMPAIGN_EDIT__ROUTE_NAME: "Edit Campaign",
  CAMPAIGN_EDIT__STATUS: "#{selected}/#{count} of items selected",

  // Campaigns list
  CAMPAIGN_LIST__PAGE_HEADER: "Campaigns",
  CAMPAIGN_LIST__SECTION_NAME: "Campaigns for #{organization}",
  CAMPAIGN_LIST__SECTION_NAME_ADMIN: "Predefined Campaigns",
  CAMPAIGN_LIST__COLUMN_DISTRIBUTOR: "Distributor",
  CAMPAIGN_LIST__COLUMN_IS_ENABLED: "Is Enabled?",
  CAMPAIGN_LIST__COLUMN_SCORE: "Score",
  CAMPAIGN_LIST__COLUMN_SCHEDULE: "Schedule",
  CAMPAIGN_LIST__SEARCH_PLACEHOLDER: "Search for campaign name",
  CAMPAIGN_LIST__CREATE_BUTTON: "Create Campaign",
  CAMPAIGN_LIST__REMOVE_CAMPAIGN: "Remove Campaign",
  CAMPAIGN_LIST__REMOVE_CAMPAIGN_MODAL_TITLE: "Warning",
  CAMPAIGN_LIST__SWITCH_ONGOING_CAMPAIGN_MODAL_TEXT:
    "You are going to #{action} campaign that is currently ongoing. Are you sure that you would like to confirm changes?",
  CAMPAIGN_LIST__SWITCH_ONGOING_CAMPAIGN_MODAL_ENABLE_ACTION: "enable",
  CAMPAIGN_LIST__SWITCH_ONGOING_CAMPAIGN_MODAL_DISABLE_ACTION: "disable",
  CAMPAIGN_LIST__REMOVE_CAMPAIGN_MODAL_TEXT:
    "is #{status}. Do you want to remove it?",
  CAMPAIGN_LIST__FILTERS_SECTION_NAME: "Campaign filters",
  CAMPAIGN_LIST__FILTERS_DATE_DESCRIPTION: "Filter campaigns by date range",
  CAMPAIGN_LIST__FILTERS_DEVICE_MODEL_DESCRIPTION:
    "Filter by the device model. Can be repeated",
  CAMPAIGN_LIST__FILTERS_DEVICE_FAMILY_DESCRIPTION:
    "Filter by the device family. Can be repeated",
  CAMPAIGN_LIST__FILTERS_COUNTRY: "Filter by the country. Can be repeated",
  CAMPAIGN_LIST__FILTERS_DATE_SPAN: "Filter campaigns by date range",
  CAMPAIGN_LIST__FILTERS_SEARCH: "Filter campaigns by name",
  CAMPAIGN_LIST__FILTERS_PROMOTION_NAME: "Filter campaigns by promotion name",

  // Campaigns edit/create
  CAMPAIGN_EDIT__PAGE_HEADER: "Campaign",
  CAMPAIGN_EDIT__ONGOING_WARNING: "You are editing ongoing campaign",
  CAMPAIGN_EDIT__SECTION_BASIC_DETAILS: "Basic details",
  CAMPAIGN_EDIT__SECTION_SCOPE: "Scope of this campaign",
  CAMPAIGN_EDIT__SECTION_SCORE: "Score",
  CAMPAIGN_EDIT__SECTION_TARGETS: "Targets",
  CAMPAIGN_EDIT__SECTION_PROMOTIONS: "Promotions",
  CAMPAIGN__CREATE_BUTTON: "Add new campaign",
  CAMPAIGN_EDIT__FIELD_NAME: "Campaign name",
  CAMPAIGN_EDIT__FIELD_NAME_TOOLTIP: "Campaign name",
  CAMPAIGN_EDIT__FIELD_DESCRIPTION: "Custom description",
  CAMPAIGN_EDIT__FIELD_DESCRIPTION_TOOLTIP: "Campaign description",
  CAMPAIGN_EDIT__FIELD_AUTO_SCORE: "Auto-calculated score (recommended)",
  CAMPAIGN_EDIT__FIELD_IS_ENABLED: "Is enabled?",
  CAMPAIGN_EDIT__FIELD_IS_ENABLED_TOOLTIP: "Enable disable campaign",
  CAMPAIGN_EDIT__FIELD_SCOPE: "Time scope",
  CAMPAIGN_EDIT__FIELD_SCOPE_TOOLTIP: "Start and end date",
  CAMPAIGN_EDIT__FIELD_SCORE: "Score",
  CAMPAIGN_EDIT__FIELD_SCORE_DESCRIPTION:
    "Campaign score is calculated automatically based on factors like Time Scope and Targets. <strong>More specific campaigns have bigger score and higher priority.</strong> However, in special cases, you can overwrite score manually. If two campaigns share same score, the newer one will be taken as the main one, also if self-created (by Promotion Manager) campaigns shares same score as Promotion Administrator's campaign the Self-created (by Promotion Manager) will be taken as the main one",
  CAMPAIGN_EDIT__FIELD_PROMOTIONS_NOT_EMPTY_ERROR:
    "At least one promotion is required",
  CAMPAIGN_EDIT__FIELD_TARGETS_DEVICE_NOT_EMPTY_ERROR:
    "Each Target must have Device specified",
  CAMPAIGN_EDIT__FIELD_PROMOTIONS_CHANGE_PROMOTIONS: "Change Promotions",
  CAMPAIGN_EDIT__FIELD_PROMOTIONS_PROMOTION_NAME: "Promotion Name",
  CAMPAIGN_EDIT__FIELD_PROMOTIONS_INCLUDE_APPS: "Include Apps",
  CAMPAIGN_EDIT__FIELD_PROMOTIONS_SEARCH_FOR_PROMOTION: "Search for promotion",

  CAMPAIGN_EDIT__ONGOING_CAMPAIGN_WARNING:
    "You are going to change campaign that is currently ongoing. Are you sure that you would like to confirm changes?",
  CAMPAIGN__CREATE_SUCCESS: "The campaign has been added",

  // Campaigns filters
  CAMAPIGN_FILTER_NAME__DATE: "date",
  CAMPAIGN_FILTER_NAME__CREATOR: "campaign_creator",
  CAMPAIGN_FILTER_NAME__PROMOTION: "promotion",
  CAMPAIGN_FILTER_NAME__SEARCH: "search",
  CAMPAIGN_FILTER_NAME__DEVICE_MODEL: "devicemodel",
  CAMPAIGN_FILTER_NAME__DEVICE_FAMILY: "devicefamily",
  CAMPAIGN_FILTER_NAME__COUNTRY: "country",

  // Schedule
  SCHEDULE__ROUTE_NAME: "Schedule",
  SCHEDULE_ADMIN__ROUTE_NAME: "Predefined Schedule",
  SCHEDULE__PAGE_HEADER: "Schedule",
  SCHEDULE__ACTUAL_SCHEDULE_HEADER: "Schedule for #{organization}",
  SCHEDULE__ACTUAL_SCHEDULE_HEADER_ADMIN: "Predefined Schedule",

  // Users list
  USER_LIST__PAGE_HEADER: "Users",
  USER_ADMIN__PAGE_HEADER: "Users Admin",
  USER_LIST__SECTION_NAME: "User list",
  USER_LIST__SEARCH_PLACEHOLDER: "Search for user",

  // User details/edit
  USER_DETAILS__PAGE_HEADER: "User details",
  USER_DETAILS__TAB_DETAILS: "View Details",
  USER_DETAILS__TAB_EDIT: "Edit Details",

  USER_EDIT__REMOVE_FROM_ORG_SUCCESS:
    "The user has been removed from organization",
  USER_EDIT__BASIC: "Basic information",
  USER_EDIT__ORG_MEMBESHIP: "Organization membership",
  USER_EDIT__ROLES: "Roles within organization",
  USER_EDIT__REMOVE_MEMBERSHIP_SELF:
    "You are about to remove your membership from #{organizationName}. Be informed, that this operation cannot be undone by yourself, and will remove all your roles from this organization.",
  USER_EDIT__REMOVE_MEMBERSHIP:
    "You are about to remove user membership from #{organizationName}. This will also remove all user roles around that organization. Are you sure?",
  USER_EDIT__ADD_ORGANIZATION: "Add organization",
  USER_EDIT__CHANGE_ROLE: "Change role",
  USER_EDIT_AVATAR_IMAGE_INFO:
    "#{format}, MAX RESOLUTION #{maxResolution} ASPECT RATIO: #{aspectRatio}",
  USER_EDIT_AVATAR_IMAGE_ERROR:
    "Error uploding avatar image:  #{error}. Please try again.",

  // User Admin
  USER_ADMIN__MANAGE_USER: "Manage user in organizations",
  USER_ADMIN__ACTIVATE_USER: "Activate user",
  USER_ADMIN__ACTIVATE_USER_WARNING:
    "You are about to activate user: #{name}. Are you sure?",
  USER_ADMIN__ACTIVATE_USER_CONFIRM: "Yes, activate",
  USER_ADMIN__DEACTIVATE_USER: "Deactivate user",
  USER_ADMIN__DEACTIVATE_USER_WARNING:
    "You are about to deactivate user: #{name}. Are you sure?",
  USER_ADMIN__DEACTIVATE_USER_CONFIRM: "Yes, deactivate",
  USER_ADMIN__DEACTIVATED: "deactivated",
  USER_ADMIN__ACTIVE: "active",
  USER_ADMIN__ACTIVATE: "activate",
  USER_ADMIN__DEACTIVATE: "deactivate",
  USER_ADMIN__ACCOUNT_STATUS: "Account Status",
  USER_ADMIN__ADD_USER_TO_ORGANIZATION: "Add user to organization",
  USER_ADMIN__CHANGE_ROLE: "Change role",
  USER_ADMIN__NO_ROLES_ERROR: "At least one role should be picked",
  USER_ADMIN__FILTERS_STATUS: "Filter by the user account status",
  USER_ADMIN__FILTERS_ORGANIZATION_ID:
    "Filter by the ID of organization. Can be repeated.",
  USER_ADMIN__FILTERS_EMAIL:
    "Filter by the user registration email. Can be repeated.",
  USER_ADMIN__REMOVE_HEADER: "Warning",

  // User invite
  USER_INVITE__ROUTE_NAME: "Invite user",
  USER_INVITE__PAGE_HEADER: "Invite user to organization",
  USER_INVITE__SUCCESS: "The user has been invited",
  USER_INVITE__USER_DETAILS: "User details",
  USER_INVITE__BUTTON: "Invite user",
  USER_INVITE__NO_ACCOUNT:
    'The specified user account does not exist. Please ask user to create an account <a href="#{cloudUrl}" target="_blank" rel="noopener noreferrer">here</a> and log in.',

  // Roles descriptions
  ROLE_ORGANIZATION_OWNER:
    "Grants the user with all possible tools and permissions that are accessible for that organization. It implicitly grants the user all possible roles available within a specific organization.",
  ROLE_ORGANIZATION_ROLE_MANAGER:
    "Grants the user with tools and permissions to assign roles to other organization members. Role Manager can assign only the roles that are already assigned to him (implicitly or explicitly).",
  ROLE_ORGANIZATION_MEMBER:
    "Grants the user with permission to act on behalf of the specific organization.",
  ROLE_PROVIDER_CREATOR:
    "Grants the user with tools and permissions to create new and modify applications. Note that the 'Signatory' role is required in addition for submit such an update to the application.",
  ROLE_PROVIDER_SIGNATORY:
    "Grants the user with tools and permissions to sign documents in the application submission process and perform tasks that require such permissions (e.g. final application submission).",
  ROLE_MODERATOR_APP_INTEGRATOR:
    "Grants the user in Customer organization rights to add/remove/edit application configuration (aka application manifests)",
  ROLE_MODERATOR_MODERATOR:
    "Grants the user with tools and permissions to moderate applications.",
  ROLE_MODERATOR_CONTENT_MANAGER:
    "Grants the user with tools and permissions to manage content, like accessing application data, managing contracts, prioritization, etc.",
  ROLE_BROWSER_CONFIG_ADMIN:
    "	User with this role can list, add and delete configuration files for Android Vewd Browser. This is for Home Screen customization based on browser version, region, country, etc.",
  ROLE_VEWD_IDS_MANAGER:
    "Grants user with tools and permissions to use scripts for creation/revokation of VCCs and SDIDs.",
  ROLE_DISTRIBUTOR_DISTRIBUTION_PLANNER:
    "Grants the user with tools and permissions to define distribution plans for applications and manage the availability of the application approved by Vewd moderator for a given organization.",
  ROLE_DISTRIBUTOR_DEVICE_MANAGER:
    "Grants the user with tools and permissions to list and check details of devices registered to a given organization.",
  ROLE_ADMINISTRATOR_ORGANIZATION_ADMIN:
    "Admin role that allows a user to access and edit data for any organization.",
  ROLE_ADMINISTRATOR_DISTRIBUTION_ADMIN:
    "Admin role that allows a user to access, create and edit distribution plans for any 'Distributor' type organization.",
  ROLE_ADMINISTRATOR_DEVICE_ADMIN:
    "Admin role that allows a user to access, create and edit device model for any 'Distributor' type organization.",
  ROLE_ADMINISTRATOR_PROMOTION_ADMIN:
    "Allows the user to manage promotions for all devices from all 'Distributor' type organizations.",
  ROLE_ADMINISTRATOR_STRUCTURES_ADMIN:
    "Grants user access to the \"STRUCTURES\" section in Vewd Cloud and the ability to view, create and edit admin Content Structure), that can be deployed on devices from any 'Distributor' type organization.",
  ROLE_ADMINISTRATOR_USER_ADMIN:
    "User with this permission is able to manage users in any organization. Including assigning roles, adding and removing into/from any organization.",
  ROLE_SOFTWARE_UPDATES_UPDATE_MANAGER:
    'Grants the user with tools and permissions to the "UPDATE" section in Vewd Cloud and the ability to view/create Update Jobs and upload Software Packages for a given organization.',
  ROLE_DEVICE_INVENTORY_DEVICE_MANAGER:
    'Grants the user with tools and permissions to the "DEVICES" section in Vewd Cloud and the ability to view Device Inventory where all device units are displayed and creation of Filters and Queries for the devices for a given organization.',
  ROLE_STRUCTURES_STRUCTURE_MANAGER:
    'Grants user access to the "STRUCTURES" section in Vewd Cloud and the ability to view, create and edit Content Structures for a given organization.',
  ROLE_PROMOTION_MANAGEMENT_PROMOTION_MANAGER:
    "Grants user ability to manage distributors promotions of the application on the devices of a given distributor.",

  // Organizations
  ORGANIZATION__CREATED: "The organization has been added",
  ORGANIZATION__BASIC_INFO_HEADER: "Basic information",
  ORGANIZATION__ADDRESS_HEADER: "Address",
  ORGANIZATION__SUPPORT_HEADER: "Support",
  ORGANIZATION__CREATE_BUTTON: "Create organization",
  ORGANIZATION__ID_LABEL: "ID",
  ORGANIZATION__CITY_LABEL: "City",
  ORGANIZATION__COUNTRY_LABEL: "Country",
  ORGANIZATION__SELECT_COUNTRY_PLACEHOLDER: "Country",
  ORGANIZATION__DESCRIPTION_LABEL: "Description",
  ORGANIZATION__LOGO_TOOLTIP: "Logo. Format png required",
  ORGANIZATION__LOGO_LABEL: "Logo",
  ORGANIZATION__NAME_LABEL: "Name",
  ORGANIZATION__PHONE_LABEL: "Phone",
  ORGANIZATION__STREET_LABEL: "Street",
  ORGANIZATION__SUPPORT_EMAIL_LABEL: "Support e-mail",
  ORGANIZATION__TYPE_LABEL: "Organization type",
  ORGANIZATION__TYPE_DISTRIBUTOR_DEPENDENT_TOOLTIP:
    "To assign this organization, assigning 'Distributor' organization is required",
  ORGANIZATION__WEBSITE_LABEL: "Website",
  ORGANIZATION__ZIP_CODE_LABEL: "Zip code",
  ORGANIZATION__CREATE_PAGE_HEADER: "Create new organization",
  ORGANIZATION__ORGANIZATION_PAGE_HEADER: "Organization",
  ORGANIZATION__ORGANIZATION_ROUTE_NAME: "Organization",
  ORGANIZATION__MY_PAGE_HEADER: "My organization",
  ORGANIZATION_LIST__PAGE_HEADER: "Organizations",
  ORGANIZATION_LIST__ICON_COLUMN_LABEL: "icon",
  ORGANIZATION_LIST__NAME_COLUMN_LABEL: "name",
  ORGANIZATION_LIST__TYPE_COLUMN_LABEL: "organization type",
  ORGANIZATION_LIST__USERS_COLUMN_LABEL: "users",
  ORGANIZATION_LIST__HEADER: "Organizations list",
  ORGANIZATION_LIST__SEARCH_PLACEHOLDER: "Search for organization",
  ORGANIZATION_LIST__HIDE_FREELANCE_LABEL: "Hide freelance organizations",

  // Devices
  DEVICES__ROUTE_NAME: "Devices",
  DEVICES__EDIT_FAMILY: "Edit device family",
  DEVICES__ADD_FAMILY: "Add device family",
  DEVICES__ADD_NEW_FAMILY: "Add new device family",
  DEVICES__FAMILY_NAME_LABEL: "Device family name",
  DEVICES__FAMILY_DESCRIPTION_LABEL: "Device family description",
  DEVICES__FAMILY_LABEL: "Device family",
  DEVICES__TEST_DEVICE_STATUS: "Test Device",
  DEVICES__DISABLED_STATUS: "Disabled",
  DEVICES__CHOOSE_FAMILY: "Choose device family",
  DEVICES__CHOOSE_DISTR_CUSTOMER: "Choose distribution customer",
  DEVICES__IF_YOU_CANNOT_FIND:
    "If you cannot find a proper Device Family, you can",
  DEVICES__DEVICE_MODEL_ADDED: "The device model has been added",
  DEVICES__MODEL_DETAILS_HEADER: "Model details",
  DEVICES__MODEL_NAME_LABEL: "Model name",
  DEVICES__DISTR_CUSTOMER_LABEL: "Distribution customer",
  DEVICES__SLUG_LABEL: "Slug",
  DEVICES__AUTOGENERATED_LABEL: "autogenerated",
  DEVICES__SETTINGS_HEADER: "Settings",
  DEVICES__ENABLED_LABEL: "enabled",
  DEVICES__TESTING_LABEL: "testing",
  DEVICES__SUPPORT_HEADER: "Support",
  DEVICES__VIDEO_FORMATS_HEADER: "Video formats",
  DEVICES__ADAPTIVE_STREAMING_HEADER: "Adaptive streaming",
  DEVICES__DRM_HEADER: "DRM",
  DEVICES__ADD_NEW_MODEL: "Add new device model",
  DEVICES__ADD_NEW_MODEL_PAGE_HEADER: "Add new device model",
  DEVICES__DEVICE_FILTERS_AND_QUERIES_HEADER: "Queries",
  DEVICES__DEVICE_INVENTORY_HEADER: "Device inventory",
  DEVICES__ICON_COLUMN_NAME: "icon",
  DEVICES__NAME_COLUMN_NAME: "name",
  DEVICES__APPS_PUBLISHED_ON_DEVICE_HEADER:
    "Apps published on this device model",
  DEVICES__DEVICE_DETAILS_HEADER: "Device model details",
  DEVICES__MODEL_NAME_HEADER: "Model name",
  DEVICES__DISTRIBUTOR_HEADER: "Distributor",
  DEVICES__DEVICE_FAMILY_HEADER: "Device family",
  DEVICES__DEVICE_SLUG_HEADER: "Device slug (user agent)",
  DEVICES__VIEW_DETAILS_TAB: "Device Model Details",
  DEVICES__EDIT_DETAILS_TAB: "Edit Model Details",
  DEVICES__APPLICATIONS_TAB: "Applications",
  DEVICES__DEVICES_PAGE_HEADER: "Device models",
  DEVICES__DEVICE_FAMILY_ADDED: "The device family has been added",
  DEVICES__DEVICE_LIST_HEADER: "Device models list",
  DEVICES__DEVICE_LIST_SEARCH_PLACEHOLDER:
    "Search for device model or device family",
  DEVICES__DEVICE_LIST_SEARCH_HINT: "Press enter to perform search",
  DEVICES__NO_FAMILES_INFO: "No families",
  DEVICES__NO_DEVICES_INFO: "No device models",
  DEVICES__NO_DEVICE_MODELS_INFO: "No device models",
  DEVICES__MY_DEVICES_PAGE_HEADER: "My device models",
  DEVICES__MY_DEVICES_SEARCH_PLACEHOLDER:
    "Search for device family or device model",
  DEVICES__DEVICE_FILTERS_SEARCH_PLACEHOLDER: "Search Query by name",
  DEVICES__DEVICE_FILTERS_CREATE: "Create new Query",
  DEVICES__DEVICE_FILTERS_DETAILS: "Details",
  DEVICES__DEVICE_FILTERS_EDIT_FILTER_SUCCESS_INFO:
    "The saved Query has been sucessfully edited.",
  DEVICES__DEVICE_FILTERS_CREATE_UPDATE: "Create a new Update Job",
  DEVICES__DEVICE_FILTERS_NO_RESULTS: "We couldn't find anything.",
  DEVICES__DEVICE_FILTERS_FETCH_ERROR: "Could not fetch data.",
  DEVICES__DEVICE_FILTERS_DELETE_MODAL_HEADER: "Warning",
  DEVICES__DEVICE_FILTERS_DELETE_MODAL_WARNING:
    "You are about to delete a saved Query and you will be not able to restore it. Do you still want to delete it?",
  DEVICES__DEVICE_FILTERS_DELETE_MODAL_ERROR:
    "Oops... sorry there was an error with the request.",
  DEVICES__DEVICE_FILTERS_TABLE_FILTER_NAME: "Name",
  DEVICES__DEVICE_FILTERS_TABLE_FILTER_QUANTITY: "Number of filters",
  DEVICES__DEVICE_FILTERS_TABLE_CREATION_DATE: "Creation date",
  DEVICES__DEVICE_INVENTORY_ALL_DEVICES_TAB: "All Devices",
  DEVICES__DEVICE_INVENTORY_ALERTS_TAB: "Alerts",
  DEVICES__DEVICE_INVENTORY_DISMISSED_ALERTS_TAB: "Dismissed Alerts",
  DEVICES__DEVICE_INVENTORY_DEVICES_TABLE_DISMISS_ALERT_SELECT_OPTION:
    "Dismiss alert",
  DEVICES__DEVICE_INVENTORY_COUNTER:
    "#{count} devices selected #{all}(#{date})",
  DEVICES__DEVICE_INVENTORY_COUNTER_ALL_DEVICES: "(All devices)",
  DEVICES__DEVICE_INVENTORY_SAVE_FILTER: "Save Query",
  DEVICES__DEVICE_INVENTORY_SAVE_FILTER_TOOLTIP:
    "Add filters and save them. Saved filters can be used to create update jobs.",
  DEVICES__DEVICE_INVENTORY_FILTER_SAVED_A:
    "The new Query ”#{name}” has been successfully saved. Go to",
  DEVICES__DEVICE_INVENTORY_FILTER_SAVED_B: "to manage it",
  DEVICES__DEVICE_INVENTORY_MY_DEVICES_HEADER: "Browse and filter your devices",
  DEVICES__DEVICE_INVENTORY_MY_DEVICES_TEXT_A:
    "Here you can browse device units registered in the Inventory, by searching for specific Public Device ID's (PDID) and/or by setting filters on device attributes.",
  DEVICES__DEVICE_INVENTORY_MY_DEVICES_TEXT_B:
    "You can save a query. Note that the query definition is saved, not the results. A query is dynamic and its results might change every time you run it, because device units can be added to the Inventory, and/or their attributes values can change.",
  DEVICES__DEVICE_INVENTORY_SEARCH_PLACEHOLDER: "Search & Filter",
  DEVICES__DEVICE_INVENTORY_ADD_FILTERS:
    "Type a keyword to search PDID, or set a filter starting with /",
  DEVICES__DEVICE_INVENTORY_DEVICES_SELECTED_LABEL:
    "#{quantity} devices selected",
  DEVICES__DEVICE_INVENTORY_DEVICES_ACTION_IMPORT_ATTRIBUTES_TOOLTIP:
    "Use external csv file to update attributes of your preregistered devices",
  DEVICES__DEVICE_INVENTORY_DEVICES_TABLE_HEADER_ID: "Device ID",
  DEVICES__DEVICE_INVENTORY_DEVICES_TABLE_HEADER_DEVICE_MODEL: "Device model",
  DEVICES__DEVICE_INVENTORY_DEVICES_TABLE_HEADER_COUNTRY: "Country",
  DEVICES__DEVICE_INVENTORY_DEVICES_TABLE_HEADER_ACTIVATION_DATE:
    "Activation date",
  DEVICES__DEVICE_INVENTORY_FIELDS_WITH_LETTERS_NUMBERS_UNDERSCORE_DASH_VALIDATION_FILTER_QUERY_ERROR:
    "Incorrect filter. Either attribute name or value contains illegal characters",
  DEVICES__DEVICE_INVENTORY_ALLOWED_MANAGER_ATTRIBUTES_CHARACTERS_LIST:
    "(allowed: letters, numbers, _ and -)",
  DEVICES__DEVICE_INVENTORY_PACKAGE_VERSION_ALLOWED_CHARACTERS_LIST:
    "Package version can only contain letters, numbers, dots (.), dashes (-) and plus signs (+).",
  DEVICES__DEVICE_INVENTORY_NO_SEMVER_PACKAGE_VERSION_ALLOWED_CHARACTERS_LIST:
    "Filter can only contain letters, numbers, dots (.), dashes (-), plus signs (+) and underscores (_).",
  DEVICES__DEVICE_INVENTORY_PACKAGE_NAME_ALLOWED_CHARACTERS_LIST:
    "Package name can only contain letters, numbers, dots (.), dashes (-), plus signs (+) and underscores (_).",
  DEVICES__DEVICE_INVENTORY_INCORRECT_FILTER: "Incorrect filter.",
  DEVICES__DEVICE_INVENTORY_ONLY_POSITIVE_NUMBERS_ALLOWED_VALIDATION_ERROR:
    "Only positive numbers are allowed.",
  DEVICES__DEVICE_INVENTORY_DATES_VALIDATION_ERROR:
    "Incorrect filter. Either > or < sign is missing or date format is invalid. Expected format is YYYY-MM-DD.",
  DEVICES__DEVICE_INVENTORY_WARNING_MODAL_INFO:
    "You have unsaved filter. Do you want to continue?",
  DEVICES__DEVICE_INVENTORY_SAVE_MODAL_HEADER: "Save Query",
  DEVICES__DEVICE_INVENTORY_SAVE_MODAL_ERROR:
    "Oops... could not save the Query, please try again later.",
  DEVICES__DEVICE_INVENTORY_SAVE_MODAL_NAME_EXISTS_ERROR:
    "A Query with this name already exists. Please use a different name and try again.",
  DEVICES__DEVICE_INVENTORY_SAVE_MODAL_PLACEHOLDER: "Name:",
  DEVICES__DEVICE_INVENTORY_SAVE_MODAL_SEARCH_LABEL: "PDID keyword:",
  DEVICES__DEVICE_INVENTORY_SAVE_MODAL_INCLUDED_LABEL: "Filters:",
  DEVICES__DEVICE_INVENTORY_SAVE_MODAL_TOOLTIP_SEARCH: "Included search phrase",
  DEVICES__DEVICE_INVENTORY_SAVE_MODAL_TOOLTIP_INCLUDED: "Included filters",
  DEVICES__DEVICE_INVENTORY_SAVE_MODAL_TOOLTIP_EXCLUDED: "Excluded filters",
  DEVICES__DEVICE_INVENTORY_SAVE_MODAL_ALL_FILTERS: "All",
  DEVICES__DEVICE_INVENTORY_MODAL_NO_FILTERS_HEADER: "Warning",
  DEVICES__DEVICE_INVENTORY_MODAL_NO_FILTERS:
    "Saving query without any filters will target ALL devices in the inventory.",
  DEVICES__DEVICE_INVENTORY_MODAL_NO_FILTERS_ACCEPT:
    "I confirm I want to save a query without filters",
  DEVICES__DEVICE_LIST_ROUTE_NAME: "Device models",
  DEVICES__PROCESSING_QUEUE: "Processing Queue",
  DEVICES__DEVICE_INVENTORY_IMPORT_ATTRIBUTES_BUTTON:
    "Import manager attributes",
  DEVICES__DEVICE_INVENTORY_IMPORT_ATTRIBUTES_MODAL_TITLE:
    "Select file to import manager attribute",
  DEVICES__DEVICE_INVENTORY_IMPORT_ATTRIBUTES_SUCCESS_A:
    "The file has been uploaded successfully and is now being processed. This process may take some time depending on the number of attributes and devices. Go to the",
  DEVICES__DEVICE_INVENTORY_IMPORT_ATTRIBUTES_SUCCESS_B: "to check the status.",
  DEVICES__DEVICE_INVENTORY_IMPORT_ATTRIBUTES_DESCRIPTION:
    "This action will add additional manager attributes to your devices.",
  DEVICES__DEVICE_INVENTORY_IMPORT_ATTRIBUTES_REPLACE_WARNING_A:
    "Select this checkbox if you want to replace attributes of your devices.",
  DEVICES__DEVICE_INVENTORY_IMPORT_ATTRIBUTES_REPLACE_WARNING_B:
    "Previous manager attributes will be removed!",
  DEVICES__DEVICE_INVENTORY_OVER_THOUSAND_DEVICES_WITH_ALERT_COUNTER: "1k+",
  DEVICES__DEVICE_INVENTORY_NO_RESULTS_TOOLTIP: `No device units found. Did you expect to see some devices here?
     Double check your filters, if you have used any.
     Only devices belonging to your organization #{organization} are shown here; if you want to check devices from another organization, you need to be logged in as a member of that organization.`,
  DEVICES__DEVICE_DETAILS_PAGE_BANNER: "Test device unit",
  DEVICES__DEVICE_DETAILS_PAGE_BANNER_TOOLTIP:
    'A Test Device Unit is a device unit with manager attribute <strong>test_device_unit=yes</strong>. This manager attribute is special, it can either have the value "yes" or it will be removed. Test Device Units are used to receive Repeatable Update Jobs.',
  DEVICES__DEVICE_DETAILS_TAB_GENERAL_INFORMATION: "General information",
  DEVICES__DEVICE_DETAILS_TAB_DEVICE_INFORMATION: "Device information",
  DEVICES__DEVICE_DETAILS_TAB_MANAGER_ATTRIBUTES: "Manager attributes",
  DEVICES__DEVICE_DETAILS_TAB_SOFTWARE: "Software",
  DEVICES__DEVICE_DETAILS_INSTALLED_PACKAGES: "Installed packages",
  DEVICES__DEVICE_DETAILS_TAB_GENERIC_ATTRIBUTES: "Generic attributes",
  DEVICES__DEVICE_DETAILS_INSTALLED_PACKAGES_TOOLTIP:
    "These are software packages installed on this device unit.",
  DEVICES__DEVICE_DETAILS_REGISTERED_COMPONENTS: "Vewd software components",
  DEVICES__DEVICE_DETAILS_REGISTERED_COMPONENTS_TOOLTIP:
    "These are software components delivered by Vewd and present on this device unit.",
  DEVICES__DEVICE_DETAILS_PLATFORM_COMPONENTS: "Other software components",
  DEVICES__DEVICE_DETAILS_PLATFORM_COMPONENTS_TOOLTIP:
    "These are other software components present on this device unit.",
  DEVICES__DEVICE_DETAILS_VEWD_PACKAGES: "Vewd products",
  DEVICES__DEVICE_DETAILS_DEVICE_ACTIVITY: "Device activity",
  DEVICES__DEVICE_DETAILS_DEVICE_SPECIFICATION: "Device specification",
  DEVICES__DEVICE_DETAILS_OTHER_INFORMATION: "Other information",
  DEVICES__DEVICE_DETAILS_ERP_NUMBERS: "ERP numbers",
  DEVICES__DEVICE_DETAILS_HARDWARE_ID: "Hardware ID",
  DEVICES__DEVICE_DETAILS_ORGANIZATION: "Organization",
  DEVICES__DEVICE_DETAILS_DEVICE_MODEL: "Device model",
  DEVICES__DEVICE_DETAILS_TOTAL_RAM: "Total ram",
  DEVICES__DEVICE_DETAILS_TOTAL_STORAGE: "Total storage",
  DEVICES__DEVICE_DETAILS_ARCHITECTURE: "Architecture",
  DEVICES__DEVICE_DETAILS_LOCALE: "Locale",
  DEVICES__DEVICE_DETAILS_HARDWARE_REVISION: "Hardware revision",
  DEVICES__DEVICE_DETAILS_SYSTEM_SOFTWARE_VERSION: "System software version",
  DEVICES__DEVICE_DETAILS_STATUS: "Status",
  DEVICES__DEVICE_DETAILS_NUMBER_LIST: "Number list",
  DEVICES__DEVICE_DETAILS_USER_TYPE: "User type",
  DEVICES__DEVICE_DETAILS_LOCATION: "Location",
  DEVICES__DEVICE_DETAILS_GENERIC_ATTRIBUTE: "Generic attribute #{number}",
  DEVICES__DEVICE_DETAILS_VERSION_OF_PROVISIONING_PROTOCOL:
    "Version of provisioning protocol",
  DEVICES__DEVICE_DETAILS_VCC_SERIAL_NUMBER: "VCC serial number",
  DEVICES__DEVICE_DETAILS_FIRST_REGISTRATION: "Date/time of first registration",
  DEVICES__DEVICE_DETAILS_FIRST_REGISTRATION_LOCATION:
    "Location of first registration",
  DEVICES__DEVICE_DETAILS_LAST_CONTACT: "Date/time of last contact",
  DEVICES__DEVICE_DETAILS_LAST_CONTACT_LOCATION: "Location of last contact",
  DEVICES__DEVICE_DETAILS_TABLE_HEADER_PRODUCT: "Product",
  DEVICES__DEVICE_DETAILS_TABLE_HEADER_PACKAGE_VERSION: "Version",
  DEVICES__DEVICE_DETAILS_TABLE_NO_INSTALLED_PACKAGES: "No info available",
  DEVICES__DEVICE_DETAILS_TABLE_NO_SUPPORTED_SIGNATURES:
    "This device doesn't have any supported signatures.",
  DEVICES__DEVICE_DETAILS_PDID_HISTORY_ID_COLUMN: "PDID",
  DEVICES__DEVICE_DETAILS_PDID_HISTORY_VCC_COLUMN: "VCC Serial number",
  DEVICES__DEVICE_DETAILS_PDID_HISTORY_GENERATION_DATE_COLUMN:
    "Generation date/time",
  DEVICES__DEVICE_DETAILS_PDID_HISTORY_LAST_USAGE_DATE_COLUMN:
    "Date/time of last usage",
  DEVICES__DEVICE_DETAILS_PDID_HISTORY_ACTIVE_ALERT_WARNING_MESSAGE:
    'Warning! This device is still using a previous PDID after a new one has been generated. In normal conditions this should never happen, please double check. To dismiss the alert, move your mouse over the red icon and then click on "Dismiss this alert".',
  DEVICES__DEVICE_DETAILS_PDID_HISTORY_ACTIVE_ALERT_TOOLTIP:
    "Warning! This device is still using a previous PDID after a new one has been generated. In normal conditions this should never happen, please double check before dismissing this alert.",
  DEVICES__DEVICE_DETAILS_PDID_HISTORY_DISMISSED_ALERT_TOOLTIP:
    "This alert was dismissed. The system had detected that this device was using the previous PDID after the new one had been generated.",
  DEVICES__DEVICE_DETAILS_DISMISS_ALERT_MODAL_SUBMIT_BUTTON: "Dismiss",
  DEVICES__DEVICE_DETAILS_DISMISS_ALERT_MODAL_DESCRIPTION:
    "You are about to dismiss this alert. Are you sure?",
  DEVICES__DEVICE_DETAILS_TABLE_DISMISS_ALERT_BUTTON: "Dismiss this alert",
  DEVICES__DEVICE_DETAILS_TABLE_NO_PDID_HISTORY_FOUND: "No PDID found.",
  DEVICES__DEVICE_DETAILS_MANAGER_ATTRIBUTES_NAME_COLUMN: "Name",
  DEVICES__DEVICE_DETAILS_MANAGER_ATTRIBUTES_VALUE_COLUMN: "Value",
  DEVICES__DEVICE_DETAILS_MANAGER_ATTRIBUTES_SUCCESS_ADD:
    "Success! The Manager Attribute has been added.",
  DEVICES__DEVICE_DETAILS_MANAGER_ATTRIBUTES_SUCCESS_EDIT:
    "Success! The Manager Attribute has been changed successfully.",
  DEVICES__DEVICE_DETAILS_MANAGER_ATTRIBUTES_SUCCESS_REMOVE:
    "Success! The Manager Attribute has been removed successfully.",
  DEVICES__DEVICE_DETAILS_ADD_ATTRIBUTE_MODAL_HEADER: "Add Manager Attribute",
  DEVICES__DEVICE_DETAILS_ADD_ATTRIBUTE_BUTTON: "Add Manager Attribute",
  DEVICES__DEVICE_DETAILS_ADD_ATTRIBUTE_MODAL_BUTTON: "Add",
  DEVICES__DEVICE_DETAILS_ADD_ATTRIBUTE_MODAL_DESCRIPTION:
    "You are about to add a Manager Attribute to the device.",
  DEVICES__DEVICE_DETAILS_ADD_ATTRIBUTE_MODAL_VALIDATION:
    "Attribute name and value cannot be empty. Only alphanumeric characters and underscore are allowed.",
  DEVICES__DEVICE_DETAILS_EDIT_ATTRIBUTE_MODAL_VALIDATION:
    "Attribute value cannot be empty. Only alphanumeric characters and underscore are allowed.",
  DEVICES__DEVICE_DETAILS_EDIT_ATTRIBUTE_MODAL_HEADER: "Edit Manager Attribute",
  DEVICES__DEVICE_DETAILS_EDIT_ATTRIBUTE_BUTTON: "Edit Manager Attribute",
  DEVICES__DEVICE_DETAILS_EDIT_ATTRIBUTE_MODAL_BUTTON: "Edit",
  DEVICES__DEVICE_DETAILS_EDIT_ATTRIBUTE_MODAL_DESCRIPTION:
    "You are about to edit a Manager Attribute of the device.",
  DEVICES__DEVICE_DETAILS_REMOVE_ATTRIBUTE_MODAL_HEADER: "Remove",
  DEVICES__DEVICE_DETAILS_REMOVE_ATTRIBUTE_BUTTON: "Remove Manager Attribute",
  DEVICES__DEVICE_DETAILS_REMOVE_ATTRIBUTE_MODAL_BUTTON: "Remove",
  DEVICES__DEVICE_DETAILS_REMOVE_ATTRIBUTE_MODAL_DESCRIPTION_PT_1:
    "You are about to remove Manager Attribute from the device",
  DEVICES__DEVICE_DETAILS_REMOVE_ATTRIBUTE_MODAL_DESCRIPTION_PT_2:
    "Are You sure?",
  DEVICES__DEVICE_DETAILS_ADD_TEST_DEVICE_UNIT_ATTRIBUTE_TOOLTIP:
    'A Test Device Unit is a device unit with manager attribute <strong>test_device_unit=yes</strong>. This manager attribute is special, it can either have the value "yes" or it will be removed. Test Device Units are used to receive Repeatable Update Jobs.',
  DEVICES__DEVICE_DETAILS_TEST_DEVICE_UNIT_ATTRIBUTE_TOOLTIP:
    'A Test Device Unit is a device unit with manager attribute <strong>test_device_unit=yes</strong>. This manager attribute is special, it can either have the value "yes" or it will be removed. Test Device Units are used to receive Repeatable Update Jobs.',
  DEVICES__DEVICE_DETAILS_PDID_HISTORY_LEGEND_HEADER: "Legend:",
  DEVICES__DEVICE_DETAILS_PDID_HISTORY_LAST_GENERATED_LEGEND:
    "The latest generated PDID is written in bold black.",
  DEVICES__DEVICE_DETAILS_PDID_HISTORY_PREVIOUSLY_GENERATED_LEGEND:
    "The previous generated PDIDs are written in light grey.",
  DEVICES__DEVICE_DETAILS_ATTRIBUTE_NAME: "Attribute name",
  DEVICES__DEVICE_DETAILS_ATTRIBUTE_VALUE: "Attribute value",
  DEVICES__DEVICE_DETAILS_TAB_SIGNATURES: "Signatures",
  DEVICES__DEVICE_DETAILS_TAB_PDID_HISTORY: "PDID history",
  DEVICES__DEVICE_DETAILS_ACTION_MENU_PACKAGE_VERSION:
    "Package version details",
  DEVICES__DEVICE_DETAILS_SOFTWARE_SUBTAB_INSTALLED: "Installed",
  DEVICES__DEVICE_DETAILS_SOFTWARE_SUBTAB_UPDATE: "Update job status",
  DEVICES__DEVICE_DETAILS_SOFTWARE_UPDATE_JOB_HEADER:
    "Current update job assigned",
  DEVICES__DEVICE_DETAILS__UPDATE_LABEL_SCHEDULED: "Scheduled",
  DEVICES__DEVICE_DETAILS__UPDATE_LABEL_WAITING: "Waiting",
  DEVICES__DEVICE_DETAILS__UPDATE_LABEL_ONGOING: "Ongoing",
  DEVICES__DEVICE_DETAILS__UPDATE_LABEL_FAILURE: "Failure",
  DEVICES__DEVICE_DETAILS__UPDATE_LABEL_SUCCESS: "Success",
  DEVICES__DEVICE_DETAILS_CURRENT_UPDATE_JOB_NAME: "Name",
  DEVICES__DEVICE_DETAILS_CURRENT_UPDATE_JOB_LAST_CONTACT_DATE:
    "Last contact date",
  DEVICES__DEVICE_DETAILS_CURRENT_UPDATE_JOB_STATUS: "Status",
  DEVICES__DEVICE_DETAILS_CURRENT_UPDATE_JOB_CHECKED_ON: "Checked on: ",
  DEVICES__DEVICE_DETAILS_SIGNATURES_NAME_HEADER: "Signature name",
  DEVICES__MY_DEVICE_MODELS_ROUTE_NAME: "My device models",
  DEVICES__ADD_DEVICE_MODEL_ROUTE_NAME: "Add device model",
  DEVICES__FILTERS_AND_QUERIES_ROUTE_NAME: "Queries",
  DEVICES__DEVICE_INVENTORY_ROUTE_NAME: "Device Inventory",
  DEVICES__DEVICE_INVENTORY_ALERTS_ROUTE_NAME: "Alerts",
  DEVICES__DEVICE_INVENTORY_DISMISSED_ROUTE_NAME: "Dismissed",
  DEVICES__COMMAND_DETAILS_ROUTE_NAME: "Command details",
  DEVICES__PROCESSING_QUEUE_ROUTE_NAME: "Processing Queue",
  DEVICES__COMMANDS_ROUTE_NAME: "Device Commands",
  DEVICES__EDIT_DEVICE_MODEL_ROUTE_NAME: "Edit device model",
  DEVICES__DEVICE_MODEL_DETAILS_ROUTE_NAME: "Device model details",
  DEVICES__MY_DEVICE_MODEL_DETAILS_ROUTE_NAME: "Device model details",
  DEVICES__DEVICE_MODEL_APPLICATIONS_ROUTE_NAME: "Device model applications",
  DEVICES__MY_DEVICE_MODEL_APPLICATIONS_ROUTE_NAME: "Device model applications",
  DEVICES__DEVICE_MODEL_ENABLED_OR_DISABLED:
    "The device model has been #{status}",
  DEVICES__DEVICE_MODEL_ENABLED_STATUS: "enabled",
  DEVICES__DEVICE_MODEL_DISABLED_STATUS: "disabled",
  DEVICES__FILTERS_DETAILS_FILTERS: "Keyword and Filters",
  DEVICES__FILTERS_DETAILS_CREATION_DATE: "Creation date",
  DEVICES__FILTERS_DETAILS_SUMMARY: "Other info",
  DEVICES__FILTERS_DETAILS_DEVICES_TABLE_SEARCH_BAR_PLACEHOLDER:
    "Search device id",
  DEVICES__FILTERS_DETAILS_DEVICES_TABLE_HEADER: "Device list",
  DEVICES__FILTERS_DETAILS_DETAILS_TAB: "Details",
  DEVICES__FILTERS_DETAILS_DEVICE_LIST_TAB: "Device list",
  DEVICES__FILTERS_DETAILS_DEVICES_TABLE_FILTER_INFORMATION:
    "Result of the filter changes over time. More devices will appear in the system or will be excluded due to attributes change.",
  DEVICES__FILTERS_DETAILS_DEVICES_TABLE_FILTER_DATE: "Filter result for:",
  DEVICES__FILTERS_DETAILS_DEVICES_TABLE_REFRESH: "Refresh",
  DEVICES__FILTERS_DETAILS_DEVICES_ACTION_ADD_ATTRIBUTE: "Add custom attribute",
  DEVICES__FILTERS_DETAILS_DEVICES_ACTION_DELETE_ATTRIBUTE:
    "Delete custom attribute",
  DEVICES__FILTERS_DETAILS_DEVICES_TABLE_HEADER_ID: "Device id",
  DEVICES__FILTERS_DETAILS_DEVICES_TABLE_HEADER_OEM: "OEM",
  DEVICES__FILTERS_DETAILS_DEVICES_TABLE_HEADER_ACTIVATION_DATE:
    "Activation date",
  DEVICES__FILTERS_DETAILS_DEVICES_TABLE_HEADER_LAST_CONTACT_DATE:
    "Last activity date",
  DEVICES_FILTERS_DETAILS_ALL_FILTER: "All devices",
  DEVICES__COMMANDS_HEADER: "Device commands",
  DEVICES__COMMANDS_LIST_HEADER: "Create and push commands to your devices",
  DEVICES__COMMANDS_LIST_CREATE_COMMAND: "Create new command",
  DEVICES__COMMANDS_LIST_TTL_PASSED: `Declared time to live has passed.
    The command might not be delivered to the device.`,
  DEVICES__COMMANDS_CREATE_FIELD_COMMAND_LABEL: "Command",
  DEVICES__COMMANDS_CREATE_FIELD_PAYLOAD_LABEL: "Payload",
  DEVICES__COMMANDS_CREATE_FIELD_PAYLOAD_UPLOAD: "Upload payload",
  DEVICES__COMMANDS_CREATE_FIELD_TARGET_DEVICE_DESCRIPTION:
    "Select a device from the list below. The list displays only those devices to which we are able to send the command.",
  DEVICES__COMMANDS_CREATE_FIELD_TARGET_DEVICE_LABEL: "Select devices",
  DEVICES__COMMANDS_CREATE_FIELD_TARGET_DEVICE_PLACEHOLDER: "Search",
  DEVICES__COMMANDS_CREATE_FIELD_TARGET_DEVICE_TITLE: "Select device",
  DEVICES__COMMANDS_CREATE_FIELD_TIME_TO_LIVE_LABEL: "Time to live",
  DEVICES__COMMANDS_CREATE_FIELD_TIME_TO_LIVE_TOOLTIP:
    "There is a maximum range of 5 minutes to choose from. We will try to deliver your command as soon as the device is reachable, but there won't be further attempts after the time you specified. If you leave the timer at 0 minutes and 0 seconds, there will be only one immediate attempt to deliver the command to the targeted device.",
  DEVICES__COMMANDS_CREATE_HEADER: "Create new command",
  DEVICES__COMMANDS_CREATE_HEADER_DEVICE_COMMAND: "Device command",
  DEVICES__COMMANDS_CREATE_HEADER_TARGET_DEVICES: "Target devices",
  DEVICES__COMMANDS_CREATE_MODAL_DEVICE_SELECTED: "Device selected",
  DEVICES__COMMANDS_CREATE_MODAL_TIME_TO_LIVE: "Time to live",
  DEVICES__COMMANDS_CREATE_MODAL_TITLE: "Device command calculation",
  DEVICES__COMMANDS_CREATE_MODAL_WARNING:
    "You are about to send a command to your devices. Are you sure?",
  DEVICES__COMMANDS_CREATE_ROUTE_NAME: "Create new command",
  DEVICES__COMMANDS_CREATE_SEND_COMMAND: "Send command",
  DEVICES__COMMANDS_CREATE_SEND_COMMAND_ERROR:
    "Failed to send the command. Please review the form and try again.",

  DEVICES__PROCESSING_QUEUE__DESCRIPTION:
    "The Processing Queue shows a list of tasks that are performed in the background and might take some time, such as importing device attributes.",
  DEVICES__PROCESSING_QUEUE__SEARCH_PLACEHOLDER: "Search",
  DEVICES__PROCESSING_QUEUE__TABLE_ACTION_NAME: "Action type",
  DEVICES__PROCESSING_QUEUE__TABLE_ACTION_DATE: "Action date",
  DEVICES__PROCESSING_QUEUE__TABLE_STATUS: "Status",
  DEVICES__PROCESSING_QUEUE__STATUS_DONE: "Done",
  DEVICES__PROCESSING_QUEUE__STATUS_PROCESSING: "Processing",
  DEVICES__PROCESSING_QUEUE__STATUS_ERROR: "Error",
  DEVICES__COMMAND_DETAILS_DEFAULT_HEADER: "Device Command",
  DEVICES__COMMAND_DETAILS_CLONE: "Clone command",
  DEVICES__COMMAND_DETAILS_HEADER: "Device Command (#{creationDate})",
  DEVICES__COMMAND_DETAILS_CHECKED_ON: "Checked On: #{checkedOnDate}",
  DEVICES__COMMAND_DETAILS_SECTION_HEADER: "Device command details",
  DEVICES__COMMAND_DETAILS_DEVICE: "Device",
  DEVICES__COMMAND_DETAILS_QUERY: "Query",
  DEVICES__COMMAND_DETAILS_CREATION_DATE: "Creation date",
  DEVICES__COMMAND_DETAILS_COMMAND: "Command",
  DEVICES__COMMAND_DETAILS_TARGET_COUNT:
    "Number of target devices in the query",
  DEVICES__COMMAND_DETAILS_TTL: "Time to live",
  DEVICES__COMMAND_DETAILS_STATUS: "Status",
  DEVICES__COMMAND_DETAILS_STATUS_COUNT: "Status - Device count",

  // Software package update
  UPDATES__ROUTE_NAME: "Update",
  UPDATES__SOFTWARE_PACKAGES__ROUTE_NAME: "Software Packages",
  UPDATES__SOFTWARE_PACKAGES__CODE_SNIPPET_ERROR:
    "Could not display the manifest.",
  UPDATES__SOFTWARE_PACKAGES__PACKAGE_LOADING_ERROR:
    "Could not load the Package.",
  UPDATES__SOFTWARE_PACKAGES__PACKAGE_VERSION_LOADING_ERROR:
    "Could not load the Package version.",
  UPDATES__SOFTWARE_PACKAGES__PACKAGE_MANIFEST_HEADER: "Manifest",
  UPDATES__SOFTWARE_PACKAGES___PACKAGE_MANIFEST_DOWNLOAD_BUTTON:
    "Download Manifest",
  UPDATES__SOFTWARE_PACKAGES__PACKAGE_DETAILS_HEADER:
    "Software Package Details",
  UPDATES__SOFTWARE_PACKAGES__PACKAGE_VERSION_TABS_NAME: "VERSIONS",
  UPDATES__SOFTWARE_PACKAGES__PACKAGE_DETAILS_PACKAGE_SIZE: "Package size",
  UPDATES__SOFTWARE_PACKAGES__PACKAGE_DETAILS_UPLOAD_DATE: "Upload date",
  UPDATES__SOFTWARE_PACKAGES__PACKAGE_DETAILS_PACKAGE_NAME: "Filename",
  UPDATES__SOFTWARE_PACKAGES__PACKAGE_CREATE_UPDATE_JOB: "Create Update Job",
  UPDATES__SOFTWARE_PACKAGES__PACKAGE_PACKAGE_DOWNLOAD:
    "Download software package",
  UPDATES__SOFTWARE_PACKAGES_PACKAGE_PACKAGE_DOWNLOAD_ERROR:
    "Oops... Something went wrong. Please check your internet connection or try again later.",
  UPDATES__UPLOAD_SOFTWARE_PACKAGE_MODAL_TITLE: "Upload new Software Package",
  UPDATES__UPLOAD_SOFTWARE_PACKAGE_MODAL_TEXT_A:
    "The Software Package file must be compliant with Vewd specifications.",
  UPDATES__UPLOAD_SOFTWARE_PACKAGE_MODAL_TEXT_B: "For more information click",
  UPDATES__UPLOAD_SOFTWARE_PACKAGE_MODAL_EXTENSION_HINT:
    ".update files are supported",
  UPDATES__UPLOAD_SOFTWARE_PACKAGE_HTTP_WARNING_TEXT_LINE_1:
    "You have provided a HTTP URL which is unsecure. We strongly recommend to use secure HTTPS URLs instead.",
  UPDATES__UPLOAD_SOFTWARE_PACKAGE_HTTP_WARNING_TEXT_LINE_2:
    "If you are sure that you want to use this HTTP URL please tick the checkbox here below to accept the risks, and then click the Upload button.",
  UPDATES__UPLOAD_SOFTWARE_PACKAGE_HTTP_WARNING_CHECKBOX:
    "I understand and accept the risks connected to using an unsecure HTTP URL to upload a Software Package",
  UPDATES__UPLOAD_SOFTWARE_PACKAGE_SUCCESS_A:
    "Success! The Software Package is now being processed. This process might take some time. Go to the",
  UPDATES__UPLOAD_SOFTWARE_PACKAGE_SUCCESS_B: "to check the status.",
  UPDATES__UPLOAD_SOFTWARE_PACKAGE_MODAL_PROCESSING_QUEUE_LINK:
    "Processing queue",
  UPDATES__SOFTWARE_PACKAGES_SOFTWARE_LIST: "Available Software Packages",
  UPDATES__SOFTWARE_PACKAGES_SOFTWARE_LIST_NO_RESULTS:
    "We couldn't find anything.",
  UPDATES__SOFTWARE_PACKAGES_UPLOAD_BUTTON: "Upload Software Package",
  UPDATES__SOFTWARE_PACKAGES_SEARCH_PLACEHOLDER:
    "Type a keyword to search Software Packages by name",
  UPDATES__SOFTWARE_PACKAGES_PACKAGE_NAME_HEADER: "Name",
  UPDATES__SOFTWARE_PACKAGES_VERSION_QUANTITY_HEADER: "Number of versions",
  UPDATES__SOFTWARE_PACKAGES_DEPLOY_UPDATE_ACTION: "Create new Update Job",
  UPDATES__SOFTWARE_PACKAGES_PACKAGE_DETAILS_ACTION: "Details",
  UPDATES__CREATE_UPDATE_JOB_ROUTE_NAME: "Create new Update Job",
  UPDATES__CREATE_UPDATE_JOB_HEADER: "Create new Update Job",
  UPDATES__CREATE_SAVE_AND_START: "Save and start",
  UPDATES__FORM_REQUIREMENTS_WARNING_A: "An Update Job requires at least one",
  UPDATES__FORM_REQUIREMENTS_WARNING_B: "and at least one",
  UPDATES__FORM_REQUIREMENTS_WARNING_C: "to be created.",
  UPDATES__FORM_REQUIREMENTS_WARNING_DEVICE_FILTER_CREATED: "target Query",
  UPDATES__FORM_REQUIREMENTS_WARNING_SOFTWARE_PACKAGE_UPLOADED:
    "Software Package",
  UPDATES__FORM_SECTION_GENERAL: "General information",
  UPDATES__FORM_SECTION_SOFTWARE_PACKAGE: "Software Package",
  UPDATES__FORM_SECTION_TARGET_DEVICES: "Target devices",
  UPDATES__FORM_SECTION_ROLLOUT_STEPS: "Rollout steps",
  UPRATES__FORM_SECTION_ADVANCED_OPTIONS_SWITCH: "Show advanced options",
  UPDATES__FORM_FIELD_NAME: "Name",
  UPDATES__FORM_FIELD_UPDATE_TYPE: "Update type",
  UPDATES__FORM_FIELD_UPDATE_TYPE_FORCED: "FORCED",
  UPDATES__FORM_FIELD_UPDATE_TYPE_FORCED_TOOLTIP:
    "Forced updates are executed without end-user consent",
  UPDATES__FORM_FIELD_UPDATE_TYPE_EXPLICIT: "EXPLICIT",
  UPDATES__FORM_FIELD_UPDATE_TYPE_EXPLICIT_TOOLTIP:
    "Explicit updates require end-user consent to be executed",
  UPDATES__FORM_FIELD_UPDATE_TYPE_SILENT: "SILENT",
  UPDATES__FORM_FIELD_UPDATE_TYPE_SILENT_TOOLTIP:
    "Silent updates are executed so that the end-user is not aware of them (for example during standby).",
  UPDATES__FORM_FIELD_NAME_TOOLTIP:
    "This will be the name of this Update Job in the system.",
  UPDATES__FORM_FIELD_IS_REPEATABLE_TOOLTIP:
    "A Repeatable Update Job is intended to manage stress-test setups. Unlike a regular Update Job, a Repeatable Update Job will be delivered to eligible device units multiple times, whether or not they have already received it before, and regardless if it was successful or not. Only Test Device Units can receive a Repeatable Update Job. A device unit is a Test Device Unit if it has the manager attribute <strong>test_device_unit=yes</strong>",
  UPDATES__FORM_FIELD_PLACEHOLDER: "Insert here the name for this Update Job",
  UPDATES__FORM_FIELD_CUSTOM_DOWNLOAD_SWITCH: "Custom download",
  UPDATES__FORM_FIELD_CUSTOM_DOWNLOAD_INPUT_LABEL: "Custom download url",
  UPDATES__FORM_FIELD_CUSTOM_DOWNLOAD_INPUT_PLACEHOLDER:
    "protocol://path/to/file",
  UPDATES__FORM_FIELD_DESCRIPTION: "Description",
  UPDATES__FORM_FIELD_STEP_QUANTITY:
    "Select the number of steps in this Update Job",
  UPDATES__FORM_FIELD_STEP_QUANTITY_ONE: "1 Step",
  UPDATES__FORM_FIELD_STEP_QUANTITY_MANY: "#{steps} Steps",
  UPDATES__FORM_FIELD_STEP_QUANTITY_LABEL:
    "How many devices should get updated in",
  UPDATES__FORM_FIELD_STEP_QUANTITY_LABEL_STEP: "step #{step}",
  UPDATES__FORM_FIELD_STEP_QUANTITY_LAST_STEP_VALUE: "∞ (all target devices)",
  UPDATES__FORM_FIELD_STEP_QUANTITY_ERROR:
    "Device quantity should be an integer greater than 0",
  UPDATES__FORM_FIELD_THRESHOLD:
    "Success threshold (%) to move to the next step",
  UPDATES__FORM_FIELD_THRESHOLD_TOOLTIP:
    "How many devices need to successfully update in each step, in order to move to the next step.",
  UPDATES__FORM_FIELD_THRESHOLD_ERROR:
    "The threshold should be an integer between 0 and 100",
  UPDATES__FORM_FIELD_TIME_SCOPE: "Availability period",
  UPDATES__FORM_FIELD_TIME_SCOPE_TOOLTIP:
    "The update will be available for devices during this time period.",
  UPDATES__FORM_FIELD_TIME_SCOPE_PLACEHOLDER:
    "Click here to select start and end dates",
  UPDATES__FORM_FIELD_SOFTWARE_PACKAGE:
    "Select a Software Package and a version",
  UPDATES__FORM_SELECT_SOFTWARE_PACKAGE_MODAL_SEARCH_PLACEHOLDER:
    "Type here to search Software Packages by name",
  UPDATES__FORM_SELECT_SOFTWARE_PACKAGE_MODAL_TITLE:
    "Select a Software Package",
  UPDATES__FORM_SELECT_SOFTWARE_PACKAGE_MODAL_PACKAGE_NAME: "Name",
  UPDATES__FORM_SELECT_SOFTWARE_PACKAGE_MODAL_VERSION_QUANTITY:
    "Number of versions",
  UPDATES__FORM_FIELD_TARGET_DEVICE: "Query",
  UPDATES__FORM_FIELD_IS_REPEATABLE_HEADER: "Repeatable update job",
  UPDATES__FORM_FIELD_REPEATABLE_PILL: "Repeatable",
  UPDATES__FORM_FIELD_IS_REPEATABLE:
    "Mark this update as repeatable update job",
  UPDATES__FORM_SELECT_TARGET_MODAL_SEARCH_PLACEHOLDER:
    "Type here to search Queries by name",
  UPDATES__FORM_SELECT_TARGET_MODAL_TITLE: "Select a saved Query",
  UPDATES__FORM_SELECT_TARGET_MODAL_GROUP_NAME: "Name",
  UPDATES__FORM_NAME_EXISTS_ERROR:
    "An Update Job with this name already exists. Please use a different name and try again.",
  // Processing queue
  UPDATES__PROCESSING_QUEUE__ROUTE_NAME: "Processing queue",
  UPDATES__PROCESSING_QUEUE__TABLE_NAME: "Software Package name",
  UPDATES__PROCESSING_QUEUE__TABLE_UPLOAD_DATE: "Upload date",
  UPDATES__PROCESSING_QUEUE__TABLE_STATUS: "Status",
  UPDATES__PROCESSING_QUEUE__TABLE_NO_RESULTS: "We couldn't find anything.",
  UPDATES__PROCESSING_QUEUE__SEARCH_PLACEHOLDER:
    "Type here to search Packages by name",
  UPDATES__PROCESSING_QUEUE__STATUS_PENDING: "Pending",
  UPDATES__PROCESSING_QUEUE__STATUS_STARTED: "Started",
  UPDATES__PROCESSING_QUEUE__STATUS_VALIDATED: "Validated",
  UPDATES__PROCESSING_QUEUE__STATUS_DONE: "Done",
  UPDATES__PROCESSING_QUEUE__STATUS_SIGNING_ERROR: "Signing error",
  UPDATES__PROCESSING_QUEUE__STATUS_MANIFEST_ERROR: "Error in manifest",
  UPDATES__PROCESSING_QUEUE__STATUS_OTHER_ERROR: "Error",
  UPDATES__PROCESSING_QUEUE__DESCRIPTION:
    "The Processing Queue shows a list of tasks that are performed in the background and might take some time, such as processing of Software Packages.",
  UPDATES__PROCESSING_QUEUE__ERROR_ARCHIVE_INVALID:
    'The "metadata.zip" file is not a valid archive file. Most of the time, it means that the package does not follow the required binary format. Please refer to the specification linked in the package upload form to fix it.\n\n#{error}',
  UPDATES__PROCESSING_QUEUE__ERROR_ARCHIVE_MANIFEST_NOT_FOUND:
    "The package does not contain a manifest.\n\n#{error}",
  UPDATES__PROCESSING_QUEUE__ERROR_ARCHIVE_MULTIPLE_MANIFESTS_FOUND:
    "The package contains multiple manifests.\n\n#{error}",
  UPDATES__PROCESSING_QUEUE__ERROR_ARCHIVE_INVALID_JSON:
    'The "manifest.json" file is not a valid JSON file.',
  UPDATES__PROCESSING_QUEUE__ERROR_MANIFEST_PARSING_ERROR:
    "The manifest has problems that need fixing.\n\n#{error}",
  UPDATES__PROCESSING_QUEUE__ERROR_PACKAGE_ALREADY_EXISTS:
    'The package with ID "#{packageId}" and version "#{version}" already exists in the system.',
  UPDATES__PROCESSING_QUEUE__ERROR_PACKAGE_UNAVAILABLE:
    "The package is unavailable.\n\n#{error}",
  UPDATES__PROCESSING_QUEUE__ERROR_PACKAGE_UNAVAILABLE_WITH_URL:
    "The package is unavailable.\n\nPackage URL:\n#{url}",
  UPDATES__PROCESSING_QUEUE__ERROR_PACKAGE_VALIDATION_ERROR:
    "The package has problems that need fixing.\n\n#{error}",
  UPDATES__PROCESSING_QUEUE__ERROR_PACKAGE_VALIDATION_ERROR_PAYLOAD_SIZE:
    "The package reported an invalid payload size. It means that it does not follow the required binary format. Please refer to the specification linked in the package upload form to fix it.\n\n#{error}",
  UPDATES__PROCESSING_QUEUE__ERROR_SERVER_CONNECTION_ERROR:
    "The server failed to connect to the required resources. Please try again.\n\n#{error}",
  UPDATES__PROCESSING_QUEUE__ERROR_SERVER_DATABASE_ERROR:
    "The server failed to retrieve the required data. Please try again.\n\n#{error}",
  UPDATES__PROCESSING_QUEUE__ERROR_SERVER_GENERIC_ERROR:
    "The server failed to process the package. Please review it and try again.",
  UPDATES__PROCESSING_QUEUE__ERROR_UNKNOWN_ERROR:
    "An unknown error occurred.\n\n#{error}",

  // Update jobs
  UPDATES__UPDATE_DETAILS__DETAILS_DESCRIPTION: "Update Job details",
  UPDATES__UPDATE_DETAILS__ELIGIBLE_DEVICES_DESCRIPTION: "List of devices",
  UPDATES__UPDATE_DETAILS__PAGE_BANNER: "Repeatable update job",
  UPDATES__UPDATE_DETAILS__PAGE_BANNER_TOOLTIP:
    "A Repeatable Update Job is intended to manage stress-test setups. Unlike a regular Update Job, a Repeatable Update Job will be delivered to eligible device units multiple times, whether or not they have already received it before, and regardless if it was successful or not. Only Test Device Units can receive a Repeatable Update Job. A device unit is a Test Device Unit if it has the manager attribute <strong>test_device_unit=yes</strong>",
  UPDATES__UPDATE_DETAILS__THRESHOLD_LABEL: "Success threshold:",
  UPDATES__UPDATE_DETAILS__STATUS_LABEL: "Status:",
  UPDATES__UPDATE_DETAILS__UPDATE_DETAILS_HEADER: "General info",
  UPDATES__UPDATE_DETAILS__UPDATE_DETAILS_CREATED: "Created",
  UPDATES__UPDATE_DETAILS__UPDATE_DETAILS_AVAILABILITY: "Availability period",
  UPDATES__UPDATE_DETAILS__UPDATE_DETAILS_DESCRIPTION: "Description",
  UPDATES__UPDATE_DETAILS__UPDATE_DETAILS_JOB_TYPE: "Update type",
  UPDATES__UPDATE_DETAILS__SOFTWARE_PACKAGE_HEADER: "Software Package",
  UPDATES__UPDATE_DETAILS__SOFTWARE_PACKAGE_FILENAME: "Name",
  UPDATES__UPDATE_DETAILS__SOFTWARE_PACKAGE_CUSTOM_DOWNLOAD: "Custom download",
  UPDATES__UPDATE_DETAILS__UPDATE_LOGS_HEADER: "Update logs",
  UPDATES__UPDATE_DETAILS__UPDATE_LOGS_SELECT_STEPS: "Select steps",
  UPDATES__UPDATE_DETAILS__UPDATE_LOGS_STEP: "STEP #{step}",
  UPDATES__UPDATE_DETAILS__UPDATE_STEP: "STEP #{step}",
  UPDATES__UPDATE_DETAILS__TARGET_HEADER: "Target devices",
  UPDATES__UPDATE_DETAILS__TARGET_FILTER_QUERY: "Query",
  UPDATES__UPDATE_DETAILS__UPDATE_DETAILS_IS_REPEATABLE: "Is repeatable",
  UPDATES__UPDATE_DETAILS__TARGET_DEVICE_NUMBER:
    "Number of devices (this is the query result, it might be bigger than the actual number of devices able to receive the update)",
  UPDATES__UPDATE_DETAILS__TARGET_DEVICE_NUMBER_CHECKED_ON: "Checked on: ",
  UPDATES__UPDATE_DETAILS__TARGET_DEVICES:
    "Number of target devices in the query:",
  UPDATES__UPDATE_DETAILS__ELIGIBLE_DEVICES:
    "Number of eligible devices to be updated:",
  UPDATES__UPDATE_DETAILS__LABEL_CREATED: "CREATED",
  UPDATES__UPDATE_DETAILS__LABEL_DONE: "DONE",
  UPDATES__UPDATE_DETAILS__LABEL_ENDED: "ENDED",
  UPDATES__UPDATE_DETAILS__LABEL_FAILED: "FAILED",
  UPDATES__UPDATE_DETAILS__LABEL_IN_PROGRESS: "IN PROGRESS",
  UPDATES__UPDATE_DETAILS__LABEL_NOT_STARTED: "NOT STARTED",
  UPDATES__UPDATE_DETAILS__LABEL_PAUSED: "PAUSED",
  UPDATES__UPDATE_DETAILS__LABEL_SCHEDULED: "SCHEDULED",
  UPDATES__UPDATE_DETAILS__LABEL_STOPPED: "STOPPED",
  UPDATES__UPDATE_DETAILS__LABEL_SUCESS: "SUCCESS",
  UPDATES__UPDATE_DETAILS__LABEL_WAITING: "WAITING",
  UPDATES__UPDATE_DETAILS__LOGS_SUCCESS_MESSAGE:
    "Success! You've just downloaded the logs.",
  UPDATES__UPDATE_DETAILS__LOGS_ERROR_NO_AVAILABLE:
    "There are no device logs available",
  UPDATES__UPDATE_DETAILS__LOGS_ERROR_NO_STEPS_SELECTED:
    "Please select at least one step first",
  UPDATES__UPDATE_DETAILS__LOGS_MODAL_TITLE: "Logs download",
  UPDATES__UPDATE_DETAILS__LOGS_MODAL_TEXT: `
  Depending on the number of logs, the download process may take a while. In case of questions, please`,
  UPDATES__UPDATE_ACTION__CLONE: "Clone",
  UPDATES__UPDATE_ACTION__SCHEDULE: "Schedule",
  UPDATES__UPDATE_ACTION__RESUME: "Activate",
  UPDATES__UPDATE_ACTION__PAUSE: "Pause",
  UPDATES__UPDATE_ACTION__DELETE: "Delete",
  UPDATES__UPDATE_ACTION__STOP: "Stop",
  UPDATES__UPDATE_ACTION__ARCHIVE: "Archive",
  UPDATES__UPDATE_MODAL__ACCEPT_BUTTON: "Yes, #{action}",
  UPDATES__UPDATE_MODAL__DESCRIPTION:
    "You're about to #{action} this Update Job. Are you sure?",
  UPDATES__UPDATE_DETAILS__TABLE_HEADER_STEP: "Step",
  UPDATES__UPDATE_DETAILS__TABLE_HEADER_STATUS: "Status",
  UPDATES__UPDATE_DETAILS__TABLE_HEADER_QUANTITY: "Updated devices",
  UPDATES__UPDATE_DETAILS__TABLE_HEADER_IN_PROGRESS: "Devices in progress",
  UPDATES__UPDATE_DETAILS__TABLE_HEADER_FAILURES: "Installation failures",
  UPDATES__UPDATE_DETAILS__TABLE_HEADER_POST_REBOOT_FAILURES:
    "Post-reboot failures",
  UPDATES__UPDATE_DETAILS__TABLE_HEADER_REBOOT_PENDING: "Reboot Pending",
  UPDATES__UPDATE_DETAILS__TABLE_HEADER_RATIO: "Success ratio %",
  UPDATES__UPDATE_DETAILS__TABLE_UPDATED_DEVICES_CONJUNCTION: "of",
  UPDATES__UPDATE_DETAILS__DETAILS_TAB_NAME: "Details",
  UPDATES__UPDATE_DETAILS__ELIGIBLE_DEVICES_TAB_NAME:
    "List of eligible devices",
  UPDATES__UPDATE_DETAILS__ELIGIBLE_DEVICES_ROUTE_NAME:
    "List of eligible devices",
  UPDATES__UPDATE_DETAILS__ELIGIBLE_DEVICES_LIST_PDID: "Device ID",
  UPDATES__UPDATE_DETAILS__ELIGIBLE_DEVICES_LIST_CONTACT_DATE:
    "Last contact date",
  UPDATES__UPDATE_DETAILS__ELIGIBLE_DEVICES_LIST_UPDATE_STATUS: "Update status",
  UPDATES__UPDATE_DETAILS__ELIGIBLE_DEVICES_LIST_SEARCH_PLACEHOLDER:
    "Search for PDID",
  UPDATES__UPDATE_DETAILS__ELIGIBLE_DEVICES_LIST_CHECKED_ON:
    "(Checked on: #{dateString})",

  // Update job list
  UPDATES__UPDATE_JOB_LIST__JOB_CREATED:
    "The Update Job ”#{name}” has been successfully created.",
  UPDATES__UPDATE_JOB_LIST__JOB_DELETED:
    "The Update Job ”#{name}” has been successfully deleted.",
  UPDATES__UPDATE_JOB_LIST__HEADER: "Update Job list",
  UPDATES__UPDATE_JOB_LIST__ROUTE_NAME: "Update Jobs",
  UPDATES__UPDATE_JOB_LIST__CREATE_UPDATE_JOB: "Create a new Update Job",
  UPDATES__UPDATE_JOB_LIST__CREATE_FILTER: "Create filter",
  UPDATES__UPDATE_JOB_LIST__SEARCH_PLACEHOLDER:
    "Type here to search Update Jobs by name",
  UPDATES__UPDATE_JOB_LIST__DETAILS_ACTION: "Details",
  UPDATES__UPDATE_JOB_LIST__PAUSE_UPDATE_ACTION: "Pause update",
  UPDATES__UPDATE_JOB_LIST__STOP_AND_ARCHIVE_ACTION: "Stop & archive",
  UPDATES__UPDATE_JOB_LIST__CLONE_ACTION: "Clone",
  UPDATES__UPDATE_JOB_LIST__TABLE_NAME: "Name",
  UPDATES__UPDATE_JOB_LIST__TABLE_STATUS: "Status",
  UPDATES__UPDATE_JOB_LIST__TABLE_UPDATE_STEP: "Current step",
  UPDATES__UPDATE_JOB_LIST__TABLE_CREATION_DATE: "Creation date",
  UPDATES__UPDATE_JOB_LIST__TABLE_IS_REPEATABLE: "repeatable",
  UPDATES__UPDATE_JOB_LIST__TABLE_IS_REPEATABLE_TOOLTIP:
    "A Repeatable Update Job is intended to manage stress-test setups. Unlike a regular Update Job, a Repeatable Update Job will be delivered to eligible device units multiple times, whether or not they have already received it before, and regardless if it was successful or not. Only Test Device Units can receive a Repeatable Update Job. A device unit is a Test Device Unit if it has the manager attribute <strong>test_device_unit=yes</strong>",
  UPDATES__UPDATE_JOB_LIST__NO_RESULTS_MESSAGE: "No results",
  UPDATES__UPDATE_JOB_LIST__ACTIVE_JOBS_TAB: "Active",
  UPDATES__UPDATE_JOB_LIST__ARCHIVE_JOBS_TAB: "Archived",

  // Dashboard
  DASHBOARD__PAGE_HEADER: "Dashboard",
  DASHBOARD__WELCOME_MESSAGE:
    "Welcome in the Vewd Cloud. This is your dashboard where you can find some cool features.",
  DASHBOARD__NOTIFICATIONS_HEADER: "Notifications",
  DASHBOARD__NEWS_HEADER: "News",

  // Account menu
  ACCOUNT_MENU__NOT_ASSIGNED_TO_ORGANIZATION:
    "You are not assigned to any organization.",
  ACCOUNT_MENU__CREATE_ORGANIZATION_BUTTON: "create organization",

  // Nav menu
  NAV_MENU__REQUIREMENTS: "Requirements",
  NAV_MENU__SUPPORT_AND_HELP: "Support & help",

  // Form elements
  COLOR_FIELD_PRESETS_HEADER: "Color Presets",
  INPUT_WITH_TAGS__PLACEHOLDER: "Add",
  PROGRESSBAR__LOADED_AND_TOTAL_INTEGER_ERROR:
    "Loaded and total params have to be an integer",
  PROGRESSBAR__TOTAL_NOT_ZERO_ERROR: "Total cannot be equal to zero",
  PROGRESSBAR__TOTAL_LESS_THAN_LOADED_ERROR:
    "Loaded has to be less than or equal to total",
  UPLOADER__UPLOADING_FILE: "Uploading file",
  UPLOADER__STATUS: "Status:",
  UPLOADER__STATUS_IN_PROGRESS: "In progress",
  UPLOADER__STATUS_DONE: "Done",
  UPLOADER__STATUS_ERROR: "Error",
  UPLOADER__STARTED_DATE: "Started:",

  // guidelines
  GUIDELINES__PAGE_HEADER: "Guidelines and Requirements",
  GUIDELINES__SUBMITTING_TO_APP_STORE_HEADER: "Submitting to Vewd App Store",
  GUIDELINES__SUBMITTING_TO_VEWD_APP_STORE_CONTENT: `
    <p>
      If this is your first application, you may start by going through an
      #{link}. You will find a quick overview of the Vewd App Store ecosystem,
      as well as further pointers on how to develop apps, what devices you can
      target and what features you can rely upon.
    </p>
    <p>
      At the end of the submission process, you will be able to choose if you
      submit your app for:
    </p>
    <ul>
      <li>
        <b>Certification and Distribution</b> through Vewd App Store (default
        option), or
      </li>
      <li><b>Certification only</b></li>
    </ul>
    <p>
      Please note that in the case of <b>Certification only</b> submissions,
      your app will have to be approved by a Content Manager at Vewd App Store
       <i>before</i> the actual certification process can start. A Content
      Manager may reach out to you for further information.
    </p>
    <p>
      A <b>Certification only</b> app will not be distributed through Vewd App
      Store. You will have to close distribution agreements with Manufacturers,
      OEMs and Operators you wish to distribute your application for.
    </p>
  `,
  GUIDELINES__INTRODUCTION_LINK:
    "Introduction to applications for Vewd App Store",
  GUIDELINES__REQUIREMENTS_FOR_APPS_HEADER: "Requirements for all applications",
  GUIDELINES__REQUIREMENTS_FOR_APPS_CONTENT: `
  <p>
      Irrespectively of the option you select at the end of the submission
      process, your application will have to satisfy a set of requirements.
    </p>

    <h2>
      Application requirements - if you are targeting TVs/devices released in
      2017 or later
    </h2>
    <ul>
      <li>
        The application will be checked against and will have to comply with the
        latest #{link} in the Application Certification Program.
      </li>
    </ul>
    <h2>
      Metadata requirements - information and assets required at submission:
    </h2>
    <ul>
      <li>company information (name, address, email)</li>
      <li>app name</li>
      <li>app description</li>
      <li>author name</li>
      <li>support email</li>
      <li>
        thumbnail (JPG, 480x270 or larger, with 16:9 aspect ratio, 1MB max)
      </li>
      <li>icons (JPG or PNG, 128x128px and 512x512px, 500kB max)</li>
      <li>
        screenshots of the app (JPG, 1280x720px or larger, with 16:9 aspect
        ratio, 1MB max)
      </li>
      <li>
        URL of the full-screen version of the app hosted on the external server
      </li>
      <li>languages supported by the TV app</li>
    </ul>

    <p>
      Additionally, you will need to choose and assign your app to one
      predefined application category provided by Vewd App Store (e.g. Games,
      Music, etc.)
    </p>

    <p>
      Please note that after submission, the only way to modify the app&apos;s
      metadata is to submit a new version - developers cannot edit the
      information stored in the portal.
    </p>
`,
  GUIDELINES__APP_REQUIREMENTS_LINK: "Application Requirements",
  GUIDELINES__REQUIREMENTS_FOR_DIST_APPS_HEADER:
    "Requirements for applications distributed through Vewd App Store",
  GUIDELINES__REQUIREMENTS_FOR_DIST_APPS_CONTENT: `
  <p>
      When you submit your app for Vewd App Store distribution, we will also
      evaluate it according to a set of #{link}. If it
      doesn&apos;t satisfy all the criteria, the app will be rejected, so read
      them carefully. You can track the status on the submitted app page.
    </p>
  `,
  GUIDELINES__ACCEPTANCE_CRITERIA_LINK: "Acceptance criteria",
  GUIDELINES__ACCEPTANCE_CRITERIA_HEADER: "Acceptance criteria",
  GUIDELINES__ACCEPTANCE_CRITERIA_CONTENT: `
  <h2>Application metadata must satisfy all of the following criteria:</h2>
    <ul>
      <li>
        The icons, screenshots and graphic design must be of acceptable quality.
        Anti-aliasing PNGs with transparent backgrounds are preferred. Note that
        interlaced PNG-files are currently not supported.
      </li>
      <li>
        The description is visible to end users, so it must explain the
        app&apos;s purpose.
      </li>
      <li>
        The description of the app must be grammatically correct and accurately
        describe the app.
      </li>
      <li>
        The detailed description for moderators must be written in English.
      </li>

      <li>
        The URL you provide for your app must be a valid hostname (specifically,
        the hostname labels can only contain ASCII characters a-z, 0-9 and a
        hyphen; no other punctuation or space marks are allowed).
      </li>
      <li>
        All webpages not defined as application links (i.e. app URL and its
        sub-pages) will open in the regular TV browser (after all, webpages are
        not TV store apps). To make a website load in the TV Store, add the
        top-level URL as an application link, and all of the sub-pages will also
        load in TV Store. Please make sure the pages are optimized for TV.
      </li>

      <li>
        For commercial apps, all sources of revenue from an app distributed via
        the Vewd App Store (including via in-app advertisements) must be
        disclosed to Vewd in advance.
      </li>
    </ul>

    <h2>Your app must satisfy all of the following criteria:</h2>
    <ul>
      <li>
        The app must perform as described and should not have any obvious bugs.
      </li>
      <li>
        The app contains a support email address that is easy to find by the end
        users.
      </li>
      <li>
        The app must not collect private information without authorization from
        the user.
      </li>
      <li>
        The app must not unlawfully incorporate third party information, code or
        graphics.
      </li>
      <li>
        The app must not include Vewd in the title or any other Vewd branding
        that can indicate the app was created by Vewd.
      </li>
      <li>
        The app must comply with the Terms of Service of the Vewd App Store.
      </li>
      <li>
        The app can be launched and exited without any unusual/unexpected
        behavior.
      </li>
      <li>
        The app should launch within a reasonable amount of time, and generally
        should take no longer than 10 seconds to be usable.
      </li>
      <li>
        For launch times longer than 5 seconds, make sure that there is some
        progress indicator so as not to give the user the impression that the
        app has frozen.
      </li>
      <li>
        When the user has to wait for a response from the server or similar slow
        operations, the app must provide a visual activity indicator (such as a
        spinner).
      </li>
      <li>
        The app must provide an easy and clear way for the user to return to the
        app&apos;s main screen (home page) from any other screen.
      </li>
      <li>
        If the app provides a fullscreen mode (for instance, a slideshow feature
        for a photo app), provide a clear and easy method for the user to exit
        this mode.
      </li>
      <li>
        You must make sure that all links in your app work, and don&apos;t point
        to non-existent or faulty pages.
      </li>
      <li>The app must be optimized for a resolution of 1280×720 (720p).</li>
      <li>
        The app&apos;s markup must have a valid &lt;title&gt; element containing
        the name of your app, as this is used on certain Vewd App Store devices
        to provide information to the user about which apps are currently
        running.
      </li>
      <li>
        Adobe Flash and similar multimedia plug-ins are not supported by the
        Vewd App Store and your app cannot make use of them.
      </li>
      <li>
        All audio/video content can be stopped/started without any
        unusual/unexpected behavior.
      </li>
      <li>
        Due to a limitation on the current series of devices that run the Vewd
        App Store, there is only support for playback of a single audio/video
        element at the same time.
      </li>
      <li>
        The app must provide its own scrolling mechanism if needed, as users
        can&apos;t directly interact with the native scrollbars using standard
        remote controls.
      </li>
      <li>
        The app must suppress standard dotted outlines and blue spatial
        navigation focus indicators - provide custom visual focus indication via
        CSS.
      </li>
      <li>
        Gambling or other games of chance that involve money is not allowed.
      </li>
    </ul>
    <h2>Content in your app must satisfy the following criteria:</h2>
    <ul>
      <li>All audio/video content can be played without jittering.</li>
      <li>
        Applications cannot contain any content (or advertising promoting
        material) that contains nudity; is intended for adults only; is
        unlawful; is harmful; is threatening; is defamatory; is pornographic; is
        harassing; is private; is racially, ethnically, or similarly
        objectionable; facilitates illegal activity; promotes violence; is
        discriminatory; or depicts illegal activities.
      </li>
      <li>
        The app must not unlawfully incorporate third party information, code or
        graphics.
      </li>
      <li>
        The content must comply with the Terms of Service of the Vewd App Store.
      </li>
      <li>
        If certain aspects or pieces of content cannot be accessed from certain
        parts of the world (for instance, video clips only authorised to be
        viewed in a particular country), an informative explanation should be
        provided to the user.
      </li>
      <li>
        If content/app availability should be restricted to certain countries,
        then only those countries should be selected during the submission
        process, otherwise the app can be published globally.
      </li>
      <li>
        If content/app availability should be restricted to certain TV Store
        platforms or devices, then a written request should should sent to
        moderators during submission process, otherwise the app can be published
        on any TV Store platforms or devices.
      </li>
    </ul>
    <p>
      Should you wish so, it is possible to publish your application only on
      certain devices. Please contact Vewd App Store at #{link} if you need to restrict the availability of your app by:
    </p>
    <ul>
      <li>name of the device manufacturer</li>
      <li>name of the device</li>
    </ul>
  `,
  GUIDELINES__AFTER_SUBMISSION_HEADER: "What happens after submission",
  GUIDELINES__AFTER_SUBMISSION_CONTENT: `
  <p>
      If the above app metadata requirements and criteria are satisfied, your
      app undergoes additional testing, to see that it works on our reference
      devices. Your app may also need to undergo a review process by device
      manufacturers before being made available on their platforms.
    </p>

    <p>
      An app that does not satisfy the criteria or fails the testing will be
      rejected; and you will be given information about its rejection in the app
      Message Center. After you have fixed the problems, you can resubmit the
      app by clicking the &quot;Upgrade&quot; button next to the app name on My
      Apps list.
    </p>

    <p>
      When your app satisfies all requirements and criteria, it will become
      certified and be ready for deployment on Vewd App Store powered devices.
      If you applied for Certification & Distribution (recommended), the app
      will also be published in the Vewd App Store.
    </p>
  `,
  TABLE__EXPORT_BUTTON: "export",
  TABLE__EXPORT_BUTTON_TOOLTIP_HEADER: "Export data",
  TABLE__EXPORT_BUTTON_TOOLTIP_PARAGRAPH_1: "Excel (.xlsx) or .CSV file",
  TABLE__EXPORT_BUTTON_TOOLTIP_PARAGRAPH_2:
    "Data table will be exported with all filters applied.",
  TABLE__EXPORT_MODAL_HEADER: "Exporting",
  TABLE__EXPORT_MODAL_DESCRIPTION: `
    <p>Generating a file may take a while... Report will be send to email address provided during registration. In case of any questions please #{contactUsLink}</p>
  `,
  TABLE__EXPORT_MODAL_FILE_FORMAT_LABEL: "Choose file format",
  TABLE__EXPORT_MODAL_FILE_FORMAT_XLSX_LABEL: "Excel (.xlsx)",
  TABLE__EXPORT_MODAL_FILE_FORMAT_CSV_LABEL: "CSV",
  TABLE__EXPORT_MODAL_SENSITIVE_DATA_CHECKBOX: "Export without sensitive data",
  TABLE__EXPORT_SUCCESS_MESSAGE:
    "Success! Data file will be send as soon as possible to your email address",
  TABLE__RESULTS_OF_TOTAL: "of",
  UPDATE_DETAILS__TABLE_POST_REBOOT_FAILURES_TOOLTIP_HEADER:
    "This column indicates the number of Device Units that have successfully performed the Update but reported some failure after reboot. Possible causes might include problems with the software package or the device. It is also possible that a device crashed after boot and the previous software version was restored.",
  UPDATE_DETAILS__TABLE_REBOOT_PENDING_TOOLTIP_HEADER:
    "This column indicates the number of Device Units that have successfully performed the Update but have not sent the reboot notification yet. If this number is greater than 0 after a long period of time, it might indicate that those devices have broken, or cannot connect to Internet, or end users are not accepting to perform the reboot.",
};

export type TranslationKeysType = keyof typeof DEF_LANG;
